import { createContext, ReactNode, useState } from "react";

interface childProps {
    children: ReactNode
}

export type IUtilContextProps = {
    loader: boolean
    setLoader: (e: boolean) => void
    inputError: boolean
    setInputError: (e: boolean) => void
}

export const UtilContext = createContext<IUtilContextProps | null>(null)

export const UtilContextProvider = ({ children }: childProps) => {
    const [loader, setLoader] = useState(false)
    const [inputError, setInputError] = useState(false)

    return (
        <UtilContext.Provider
            value={{
                loader,
                setLoader,
                inputError,
                setInputError,
                
            }}
        >
            {children}
        </UtilContext.Provider>
    )
}