import { apiService } from 'api/apiService';

export const getManifestApi = async (query: string) => {
  const response = await apiService.get(
    `/manifest/getManifest?${query}`,
  );
  return response;
};

export const getmanifestDestination = async () => {
  const response = await apiService.get(
    `/manifest/getDestinationList`,
  );
  return response;
};

export const getmanifestPrintInvoice = async (query: any) => {
  const response = await apiService.get(
    `/manifest/print/?${query}`,
  );
  return response;
};

export const getmanifestDestinationById = async (query: string) => {
  const response = await apiService.get(
    `/manifest/getUnmapped/?${query}`,
  );
  return response;
};
export const handleAddManifest = async (data: object) => {
  const payload = { ...data };
  const response = await apiService.post({
    url: `/manifest/addManifest`,
    payload,
  });
  return response;
};

export const handleMovenewManifest = async (data:object) => {
  const payload =  {...data} ;
  const response = await apiService.put({
    url: `/manifest/moveToNewManifest`,
    payload,
  });
  return response;
};

export const addManifestGroupApi = async (data: object) => {
  const payload = { ...data };
  const response = await apiService.put({
    url: `/manifest/addGroup`,
    payload,
  });
  return response;
};


export const handleDeleteGroupWaybillApi = async (data: object) => {
  const payload = { ...data };
  const response = await apiService.put({
    url: `/manifest/removeGroup`,
    payload,
  });
  return response;
};


export const getmanifesGetMappedEdit = async (query: string) => {
  const response = await apiService.get(
    `/manifest/getMapped/?${query}`,
  );
  return response;
};

export const getmanifesGetGroup = async (query: string) => {
  const response = await apiService.get(
    `/manifest/getGroups/?${query}`,
  );
  return response;
};

export const capitain_list_api = async () => {
  const response = await apiService.get(
    `/dispatch/captains`,
  );
  return response;
};

export const register_number_vehicle = async () => {
  const response = await apiService.get(
    `/fleet/available`,
  );
  return response;
};


export const dispatch_register_api = async (data: object) => {
  const payload = { ...data };
  const response = await apiService.post({
    url: `/dispatch/register`,
    payload,
  });
  return response;
};