

interface IRadio {
  label?: string
  value?: string | number | boolean
  defaultValue?: string | number | boolean
  name: string
  radioID?: string | number
  onClick?: () => void
  defaultRadio?: number | string
  className?: string
  labelClass?: string
  radioPointClass?: string
  spanClass?: string
  

}

export const Radio = ({ label, value, defaultValue, onClick, className, labelClass, radioPointClass, spanClass }: IRadio) => {

  return (
    <div
      onClick={() => onClick && onClick()}
      className={`radio-cont flex items-center gap-2 cursor-pointer ${className}`}
    >
      <div
        className={` ${radioPointClass} ${!value && defaultValue === label ? `bg-col_66bb6a ` : value === label ? `bg-col_66bb6a `
          : value === true ? `bg-col_66bb6a` : "bg-col_f8f8f8 "} border-2 flex items-center justify-center p-[0.2rem] rounded-[50%]  box-border `}
      >
        <span className={`${value === label ? "bg-col_f8f8f8 " : ""} ${spanClass} p-[3px] rounded-[50%]`}></span>
      </div>
      {label && <span className={`text-[0.75rem] ${labelClass}`}>{label}</span>}
    </div>
  )
}

export const RadioPoint = ({ active, onClick, disabled, outerClass, innerClass }: any) => {
  return (
    <div
      onClick={() => {
        if (!disabled) {
          onClick && onClick()
        }
      }}
      className={`w-[1.25rem] h-[1.25rem] p-[0.125rem] rounded-[16.75rem] cursor-pointer flex items-center justify-center
        ${active ? "bg-col_e84e40" : "border-2 border-col_ececec"} ${outerClass} box-border
        `}
    >
      <span className={`w-[0.5rem] h-[0.5rem] rounded-[1.67rem] ${active ? "bg-col_fff" : "bg-transparent"} ${innerClass}`}></span>
    </div>
  )
}

