import { Suspense, } from 'react';
import { Layout } from 'components/layout/Layout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthenticationLayout } from 'pages/authentication/AuthenticationLayout';
import { store } from './store/store';
import { Pages } from 'Pages';
import { UtilContextProvider } from 'context/utils/UtilContext';
import { CaptureShipmentContextProvider } from 'context/shipment/CaptureShipmentContext';
import { loader } from 'assets';
import { MoveToNewManifest } from 'pages/processing-and-packaging/move-manifest/MoveToNewManifest';
import { IndividualCustomer } from 'pages/admin-options/individual-customer/IndividualCustomer';
import { InterNationalShipmentContextProvider } from 'context/interNationalShipment/InternationalShipmentContext';
import { ExpressDropoffProvider } from "context/expressdroff/expresscontext"
import { GiggoExtensionProvider } from 'context/giggoExtension/giggoExtensioncontext';
function App() {
  // const [token,setToken]=useState<any>("")

  // useEffect(()=>{
  //   const token=sessionStorage.getItem('agility_token')
  //   setToken(token)
  // },[])
  // const isAuthenticated = !!token;
  return (
    <Provider store={store}>
      <Suspense fallback={<div>
        <img className="mx-auto my-auto" src={loader} alt="" />
      </div>}>
        <UtilContextProvider>
          <CaptureShipmentContextProvider>
            <InterNationalShipmentContextProvider>
              <ExpressDropoffProvider>
                <GiggoExtensionProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<AuthenticationLayout />} />
                    {/* <Route path='/' element={
                      isAuthenticated ? (
                        <AuthenticationLayout />

                      ) : (
                        <Navigate to={window.location.pathname} replace />
                      )
                    }/> */}
                    <Route path="/forgot-password" element={<AuthenticationLayout />} />
                    <Route path="/reset-password-otp" element={<AuthenticationLayout />} />
                    <Route path="/reset-password" element={<AuthenticationLayout />} />
                    <Route
                      path="/dashboard"
                      element={
                        <Layout> <Pages.Dashboard /> </Layout>
                      }
                    />
                    <Route
                      path='/notifications'
                      element={
                        <Layout><Pages.Notifications /></Layout>
                      }
                    />
                    <Route
                      path='/shipments/capture-shipments'
                      element={
                        <Layout><Pages.CaptureShipments /></Layout>
                      }
                    />
                    <Route
                      path='/shipments/capture-shipments/payment'
                      element={
                        <Layout><Pages.PaymentOptions /></Layout>
                      }
                    />

                    <Route
                      path='/shipments/capture-shipments/invoice'
                      element={
                        <Layout><Pages.InvoiceCaptureShipment /></Layout>
                      }
                    />
                    <Route
                      path='/sales/customer-management'
                      element={
                        <Layout><Pages.SalesCustomerManagement /></Layout>
                      }
                    />

                    <Route
                      path="/sales/customer-transactions/:id"
                      element={
                        <Layout>
                          <Pages.SalesCustomerTransactions />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/capture-shipments/otp"
                      element={
                        <Layout>
                          {' '}
                          <Pages.VerificationOtp />{' '}
                        </Layout>
                      }
                    />


                    <Route
                      path="/shipments/daily-sales"
                      element={
                        <Layout>
                          <Pages.DailySales />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/daily-sales/shipment-invoice/"
                      element={
                        <Layout>
                          <Pages.shipmentInvoice />
                        </Layout>
                      }
                    />
                    <Route
                      path='/shipments/create-return-shipment'
                      element={
                        <Layout>
                          <Pages.CreateReturnShipment />
                        </Layout>
                      }
                    />
                    <Route
                      path="/shipments/cod-processing-order"
                      element={
                        <Layout>
                          {' '}
                          <Pages.CodProcessingOrder />{' '}
                        </Layout>
                      }
                    />
                    <Route
                      path="/shipments/bank-processing-order"
                      element={
                        <Layout>
                          {' '}
                          <Pages.BankProcessingOrder />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/transfer-management"
                      element={
                        <Layout>
                          {' '}
                          <Pages.TransferManagement />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/return-shipment"
                      element={
                        <Layout>
                          {' '}
                          <Pages.ReturnShipment />{' '}
                        </Layout>
                      }
                    />
                    <Route
                      path="/shipments/demurrage-processing-order"
                      element={
                        <Layout>
                          {' '}
                          <Pages.DemurrageProcessingOrder />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path='/processing-and-packaging/group-shipment-view'
                      element={
                        <Layout><Pages.GroupShipmentView /></Layout>
                      }
                    />
                    <Route
                      path="/processing-and-packaging/group-shipment/add-group-shipment"
                      element={
                        <Layout>
                          {' '}
                          <Pages.AddGroupShipmentView />{' '}
                        </Layout>
                      }
                    />
                    <Route
                      path="/processing-and-packaging/manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.Manifest />{' '}
                        </Layout>
                      }
                    />
                    {/* <Route
                      path="/sales/gigls-tracker"
                      element={
                        <Layout>
                          {' '}
                          <Pages.SalesGIGLSTracker />{' '}
                        </Layout>
                      }
                    /> */}

                    <Route
                      path="/shipments-tracking/gigls-tracker"
                      element={
                        <Layout>
                          <Pages.SalesGIGLSTracker />
                        </Layout>
                      }
                    />


                    <Route
                      path="/processing-and-packaging/manifest/invoice"
                      element={
                        <Layout>
                          {' '}
                          <Pages.ManifestinvoiceDetail />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/processing-and-packaging/manifest/manifest-detail"
                      element={
                        <Layout>
                          {' '}
                          <Pages.ManifestDetail />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/processing-and-packaging/manifest/manifest-waybill-report"
                      element={
                        <Layout>
                          {' '}
                          <Pages.PandPWaybillReport />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/processing-and-packaging/manifest/generate-manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.GenerateManifest />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/giggo/express-dropoff"
                      element={
                        <Layout>
                          <Pages.ExpressDropOff />
                        </Layout>
                      }
                    />
                    <Route
                      path="/giggo/customer-express-dropoff"
                      element={
                        <Layout>
                          <Pages.CustomerExpressDropOff />
                        </Layout>
                      }
                    />

                    <Route
                      path="/giggo/extension-shipment"
                      element={
                        <Layout>
                          <Pages.GiggoExtensionShipment />
                        </Layout>
                      }
                    />


                    <Route
                      path="/giggo/payment"
                      element={
                        <Layout>
                          <Pages.ExpressDropOffPayment />
                        </Layout>
                      }
                    />

                    {/* <Route    ExpressDropOffPayment
                  path="/international/inbound-shipment"
                  element={
                    <Layout>
                      <Pages.InboundShipment />
                    </Layout>
                  }
                /> */}
                    <Route
                      path="/shipments-tracking/inbound-shipment"
                      element={
                        <Layout>
                          <Pages.InboundShipments />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipment-tracking/shipment-status"
                      element={
                        <Layout>
                          <Pages.ShipmentStatus />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/home-delivery-service"
                      element={
                        <Layout>
                          {' '}
                          <Pages.DeliveryLocations />{' '}
                        </Layout>
                      }
                    />


                    <Route
                      path="/admin-options/pay-for-shipment-by-wallet"
                      element={
                        <Layout>
                          {' '}
                          <Pages.PayForShipmentByWallet />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/pay-for-shipment"
                      element={
                        <Layout>
                          {' '}
                          <Pages.PayForShipmentByWalletEcommerce />
                        </Layout>
                      }
                    />

                    <Route
                      path="/processing-and-packaging/move-to-new-manifest"
                      element={
                        <Layout>
                          {' '}
                          <MoveToNewManifest />
                        </Layout>
                      }
                    />
                    <Route
                      path="/processing-and-packaging/movement-manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.MovementMenifest />
                        </Layout>
                      }
                    />
                    <Route
                      path="/processing-and-packaging/movement-manifest/generate-movement-manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.GenerateMovementManifest />
                        </Layout>
                      }
                    />

                    <Route
                      path="/processing-and-packaging/movement-manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.MovementManifest />{' '}
                        </Layout>
                      }
                    />


                    <Route
                      path="/processing-and-packaging/move-manifest/invoices"
                      element={
                        <Layout>
                          {' '}
                          <Pages.MoveManifestinvoiceDetail />{' '}
                        </Layout>
                      }
                    />


                    <Route
                      path="/processing-and-packaging/movement-manifest/generate-movement-manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.GenerateMovementManifest />{' '}
                        </Layout>
                      }
                    />
                    <Route
                      path="/processing-and-packaging/move-to-new-manifest"
                      element={
                        <Layout>
                          {' '}
                          <Pages.MoveToNewManifest />{' '}
                        </Layout>
                      }
                    />




                    <Route
                      path="/processing-and-packaging/ecommerce-quick-quote"
                      element={
                        <Layout>
                          <Pages.EcommerceQuickQuotes />
                        </Layout>
                      }
                    />

                    <Route
                      path="/shipments/capture-intl-shipments"
                      element={
                        <Layout>
                          {' '}
                          <Pages.InterNationalCapturelShipments />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/international/international-shipment"
                      element={
                        <Layout>
                          <Pages.InternationalShipment />
                        </Layout>
                      }
                    />


                    <Route
                      path="/processing-and-packaging/international-quick-quote"
                      element={
                        <Layout>
                          <Pages.ProcessingPackagingInternationalQuickQuotes />
                        </Layout>
                      }
                    />




                    <Route
                      path="/shipments-tracking/manual-scan"
                      element={
                        <Layout>
                          <Pages.ShipmentTrackingManualScan />
                        </Layout>
                      }
                    />



                    <Route
                      path="/sales/individual-customer"
                      element={
                        <Layout>
                          <Pages.SalesIndividualAndEcommerceCustomer />
                        </Layout>
                      }
                    />


                    <Route
                      path="/shipments-tracking/gun-scan"
                      element={
                        <Layout>
                          <Pages.ShipmentTrackingGunScan />
                        </Layout>
                      }
                    />


                    <Route
                      path="/processing-and-packaging/wallet-payment-log"
                      element={
                        <Layout>
                          {' '}
                          <Pages.WalletPaymentLog />{' '}
                        </Layout>
                      }
                    />

                    <Route
                      path="/admin-options/customer-options/individual-customer"
                      element={
                        <Layout>
                          {' '}
                          <IndividualCustomer />
                        </Layout>
                      }
                    />


                    <Route
                      path="/shipments/capture-intl-shipments/payment"
                      element={
                        <Layout>
                          {' '}
                          <Pages.PaymentOptions />{' '}
                        </Layout>
                      }
                    />



                  </Routes>
                </BrowserRouter>
                </GiggoExtensionProvider>

              </ExpressDropoffProvider>
            </InterNationalShipmentContextProvider>
          </CaptureShipmentContextProvider>
        </UtilContextProvider>
      </Suspense>
    </Provider>
  );
}

export default App;
