import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import {
  // bulky,
  copy_dark,
  edit_icon,
  go_faster,
  print_dark_icon,
  red_delete_icon,
  search_plus,
  // show_password,
  table_header_arrow,
  table_plus_icon,
  white_check,
} from 'assets';
import { Button } from 'components';
import { useState } from 'react';
import { formatMoney } from 'utils';
import {
  HubMnanifestTableProps,
  ShipmentDeliveryReportHubTableProps,
  HubManifestAssignementTableProps,
} from 'interface';
import { HiMagnifyingGlassPlus } from 'react-icons/hi2';
import { format } from 'date-fns';
import { companyStatus, companyType, creditDebitType, customerRank, customerType, deliveryType, depostiStatus, manifestTypeEnum, MovementStatus, onlinePaymentType, PaymentServiceType, paymentStatus, paymentTypes, SHIPMENT_SCAN_STATUS, statusColors } from './shipmentConstants';
import { toast } from 'react-toastify';
import { MdAutorenew } from 'react-icons/md';
// import DialogBox from 'components/DialogBox/DiallogBox';.

interface IHeaderComponentProps {
  headerItem?: string;
  headerTitle: string;
  onClick?: () => void;
  className?: string;
}

export const HeaderComponent = ({
  headerTitle,
  onClick,
  className,
}: IHeaderComponentProps) => {
  const [rotate, setRotate] = useState(false);
  return (
    <div className={`flex items-center gap-3 min-w-[6.5rem] ${className}`}>
      <span className="uppercase text-col_525252 text-txt_087_600">
        {headerTitle}
      </span>
      {headerTitle && (
        <img
          src={table_header_arrow}
          alt=""
          onClick={() => {
            setRotate(!rotate);
            onClick && onClick();
          }}
          className={`cursor-pointer ${rotate ? 'rotate-180' : 'rotate-0'} `}
        />
      )}
    </div>
  );
};

interface ICreateTableHeaderProps {
  key: string;
  title: string;
  action?: () => void;
}
export const createTableHeader = (headerArr: ICreateTableHeaderProps[]) => {
  let number = {
    header: 'S/N',
    cell: (row: { renderValue: () => any }) => row.renderValue(),
    accessorKey: 'serial',
  };

  const tableHeader = headerArr.map((item: ICreateTableHeaderProps) => ({
    header: () =>
      HeaderComponent({ headerTitle: item.title, onClick: item.action }),
    cell: (row: { renderValue: () => any }) => row.renderValue(),
    accessorKey: item.key,
  }));
  return [number, ...tableHeader];
};

const ColoredBox = ({ className, text, onclick }: any) => {
  // bg-col_e84e40
  return (
    <>
      <Button
        text={text}
        type="button"
        onClick={onclick}
        className={`${className} rounded-[0.5rem] py-1 px-3 flex items-center justify-center text-txt_075_400 text-col_fff `}
      />
    </>
  );
};

const TableOthers = ({ viewRow, printer, editRow, deleteRow }: any) => {
  return (
    <div className="flex items-center justify-end gap-4">
      {viewRow && (
        <img
          src={search_plus}
          alt=""
          className="cursor-pointer min-w-[1.3rem]"
          onClick={viewRow}
        />
      )}
      {printer && (
        <img
          src={print_dark_icon}
          alt=""
          className="cursor-pointer min-w-[1.3rem]"
        />
      )}
      {editRow && <img src={edit_icon} alt="" className="cursor-pointer" />}
      {deleteRow && (
        <img
          src={red_delete_icon}
          alt=""
          className="w-6 cursor-pointer opacity-50 hover:opacity-100"
        />
      )}
    </div>
  );
};

export const summaryTableHeader = [
  { key: 'no', label: '#', component: null },
  { key: 'name', label: 'Name', component: null },
  { key: 'nature', label: 'Nature', component: null },
  { key: 'weight', label: 'Weight', component: null },
];

export const dailySalesTabeHeader = [
  { key: 'waybill', title: 'WAYBILL' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'grandTotal', title: 'GRAND TOTAL' },
  { key: 'kg', title: 'KG' },
  { key: 'status', title: 'STATUS' },

  { key: 'paymentType', title: 'PAYMENT TYPE' },
  { key: 'deliveryType', title: 'DELIVERY TYPE' },
  { key: 'cash', title: 'CASH' },
  { key: 'transfer', title: 'TRANSFER' },
  { key: 'pos', title: 'POS' },
  { key: 'refCode', title: 'REF CODE' },
  { key: 'declaredValue', title: 'DECLARED VALUE' },
  { key: 'custType', title: 'CUST TYPE' },
  { key: 'user', title: 'USER' },
  { key: 'date', title: 'DATE' },
  { key: 'viewIcon', title: '' },
  { key: 'others', title: '' },
  { key: 'printIcon', title: '' },

]
// CUSTOMER[INDIVIDUAL]
export const customerTabeHeader = [
  { key: 'FirstName', title: 'FirstName' },
  // { key: 'LastName', title: 'LastName' },
  // { key: 'CustomerCode', title: 'CustomerCode' },
  { key: 'PhoneNumber', title: 'PhoneNumber' },
  { key: 'Email', title: 'Email' },
  { key: 'customerType', title: 'CustomerType' },

  { key: 'transaction', title: '' },


]
export const customerdatarows = (data: any, serialno: any) => {

  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, index: number) => {
      const serialNumber = index + 1;

      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + serialNumber}.
          </span>
        ),
        FirstName: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item.FirstName + item.LastName}
            </span>
          </div>
        ),
        // LastName: (
        //   <div className="w-full flex items-start">
        //     <span className={`text-txt_087_normal text-col_525252`}>
        //       {item.LastName}
        //     </span>
        //   </div>
        // ),
        // CustomerCode: (
        //   <div className="w-full flex items-start">
        //     <span className={`text-txt_087_normal text-col_525252 capitalize`}>
        //       {item?.CustomerCode}
        //     </span>
        //   </div>
        // ),
        Email: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.Email}
            </span>
          </div>
        ),
        customerType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {"IndividualCustomer"}
            </span>
          </div>
        ),
        PhoneNumber: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.PhoneNumber}
            </span>
          </div>
        ),


        // status: (
        //   <div className="w-full flex items-start">
        //     <span
        //       className={`text-txt_087_normal capitalize border ${item.PaymentStatus === 1
        //         ? 'border-col_219653 text-col_219653 px-4'
        //         : 'border-col_c2c7cc text-col_c2c7cc px-2'
        //         }  py-[2px] rounded-[2rem]`}
        //     >
        //       {paymentStatus[item.PaymentStatus]}
        //     </span>
        //   </div>
        // ),

        // others: (
        //   <>  {item.PaymentStatus === 0 &&
        //     <div className="w-ful flex items-start ">
        //       <span className={`text-txt_087_normal capitalize border border-col_219653 text-col_219653 px-4 py-[2px] rounded-[2rem]`}>
        //         <button onClick={item?.others?.viewPaymnetPending}>Pay</button>
        //       </span>
        //     </div>
        //   }
        //   </>),


        // printIcon: (

        //   <>
        //     {item.PaymentStatus !== 0 &&  <img
        //       src={print_dark_icon}
        //       alt=""
        //       className="cursor-pointer hover:opacity-100 opacity-50 min-w-[1.3rem]"
        //     />
        //     }
        //   </>
        // ),

        transaction: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              <button onClick={item?.others?.transactionRoute} className="flex gap-1 items-center text-white bg-[#EE786D] px-3 py-1 rounded-lg text-sm font-medium">
                Transactions
              </button>
            </span>
          </div>
        ),

      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

// CUSTOMER[ECOMMERCE]

export const EcommercecustTabeHeader = [
  { key: 'FirstName', title: 'First Name' },
  { key: 'PhoneNumber', title: 'Phone Number' },
  { key: 'Email', title: 'Email' },
  { key: 'CustomerType', title: 'CustomerType' },
  { key: 'Rank', title: 'Rank' },
  { key: 'transaction', title: '' },
]
export const ecommercecustomer = (data: any, serialno: any) => {

  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, index: number) => {
      const serialNumber = index + 1;

      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + serialNumber}.
          </span>
        ),
        FirstName: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item.Name}
            </span>
          </div>
        ),
        // CustomerCode: (
        //   <div className="w-full flex items-start">
        //     <span className={`text-txt_087_normal text-col_525252 capitalize`}>
        //       {item?.CustomerCode}
        //     </span>
        //   </div>
        // ),
        PhoneNumber: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.PhoneNumber}
            </span>
          </div>
        ),

        Email: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.Email ? item.Email : "--"}
            </span>
          </div>
        ),
        CompanyStatus: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {companyStatus[item.CompanyStatus]}
            </span>
          </div>
        ),
        // CompanyId: (
        //   <div className="w-full flex items-start">
        //     <span className={`text-txt_087_normal text-col_525252 capitalize`}>
        //       {item.CompanyId}
        //     </span>
        //   </div>
        // ),
        // CompanyType: (
        //   <div className="w-full flex items-start">
        //     <span className={`text-txt_087_normal text-col_525252 capitalize`}>
        //       {companyType[item.CompanyType]}
        //     </span>
        //   </div>
        // ),

        CustomerType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {companyType[item.CompanyType]}
            </span>
          </div>
        ),
        Rank: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {customerRank[item?.Rank]}
            </span>
          </div>
        ),
        transaction: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              <button onClick={item?.others?.transactionRoute} className="flex gap-1 items-center text-white bg-[#EE786D] px-3 py-1 rounded-lg text-sm font-medium">
                Transactions
              </button>
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};




export const customerTransactionTabeHeader = [
  { key: 'Waybill', title: 'Waybill' },
  { key: 'Departure', title: 'Departure' },
  { key: 'Destination', title: 'Destination' },
  { key: 'Description', title: 'Description' },
  { key: 'Weight', title: 'Weight' },
  { key: 'Amount', title: 'Amount' },
  { key: 'CodAmount', title: 'Cod Amount' },
  { key: 'date', title: 'DateCreated' },






]
export const customerTransactionTabRow = (data: any, serialno: any) => {
  console.log(serialno, "serialno")
  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, index: number) => {
      // const serialNumber = index + 1;

      // console.log(serialNumber,"serialNumber")

      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {/* {serialno + serialNumber}. */}
            {index + 1}.

          </span>
        ),
        Waybill: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item.Waybill ?? "--"}
            </span>
          </div>
        ),

        Departure: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.Departure ?? "--"}
            </span>
          </div>
        ),
        Destination: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.Destination ?? "--"}
            </span>
          </div>
        ),
        Description: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Description ? item.Description : "--"}
            </span>
          </div>
        ),


        Weight: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ApproximateItemsWeight ? item.ApproximateItemsWeight.toFixed(2) + "KG" : "--"}
            </span>
          </div>
        ),
        Amount: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              ₦{item.GrandTotal ?? "--"}
            </span>
          </div>
        ),
        CodAmount: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              ₦{item.CashOnDeliveryAmount ?? "--"}
            </span>
          </div>
        ),

        date: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DateCreated ? format(item.DateCreated, "dd MMMM yyyy hh:mm a") : "--"}
            </span>
          </div>
        ),


      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};


//START GIGLS TABLEHEADER
export const GiglsTrackerHeader = [
  { key: 'Waybill', title: 'Waybill' },
  { key: 'Date', title: 'Date' },
  { key: 'Time', title: 'Time' },
  { key: 'Loaction', title: 'Loaction' },
  { key: 'Status', title: 'Status' },
  { key: 'ScannedBy', title: 'ScannedBy' },
]

export const GiglsTrackerdata = (data: any, serialno: any) => {

  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, index: number) => {
      const serialNumber = index + 1;

      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + serialNumber}.
          </span>
        ),
        Waybill: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item.Waybill}
            </span>
          </div>
        ),
        Date: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DateCreated ? format(item.DateCreated, "dd MMMM yyyy") : ""}
            </span>
          </div>
        ),
        Time: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.DateModified ? format(item.DateModified, "hh:mm a") : ""}

            </span>
          </div>
        ),
        Loaction: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Location}
            </span>
          </div>
        ),
        Status: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Status}
            </span>
          </div>
        ),
        ScannedBy: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ScannedBy}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};
//ENDS GIGLS TABLEHEADER


//START EXPRESS DROPOFF PLUS
export const ExpressDropoffplusheader = [
  { key: 'precode', title: 'Pre Code	' },
  { key: 'receivername', title: 'Receiver Name' },
  { key: 'company', title: 'Company' },
  { key: 'date', title: 'Date created	' },
  { key: 'action', title: 'Action' },
  // { key: 'ScannedBy', title: 'ScannedBy' },
]

export const expressTrackerData = (data: any, serialno: any,) => {

  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, index: number) => {
      const serialNumber = index + 1;
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + serialNumber}.
          </span>
        ),
        precode: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item?.TempCode}
            </span>
          </div>
        ),
        receivername: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.ReceiverName}
            </span>
          </div>
        ),
        company: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.CompanyType}

            </span>
          </div>
        ),
        date: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {format(item.DateCreated, "dd MMMM yyyy hh:mm a")}
            </span>
          </div>
        ),
        action: (
          <div className="w-full flex items-start">
            <button onClick={item?.others?.invoice} className={`${item?.IsProcessed ? "bg-[#807E7C]" : "bg-col_e84e40 "} rounded-lg py-1 px-2 text-white text-sm font-medium capitalize`}>
              Express DropOff
            </button>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

//END EXPRESS DROPOFF PLUS





//paramsData is used to send the sortbycolumn query param in sales api
export const paramsdata = [
  { key: 'waybill', title: 'Waybill' },
  { key: 'destination', title: 'DestinationServiceCentreName' },
  { key: 'grandTotal', title: 'GrandTotal' },
  { key: 'kg', title: 'ApproximateItemsWeight' },
  { key: 'status', title: 'PaymentStatus' },
  { key: 'paymentType', title: 'PaymentMethod' },
  { key: 'deliveryType', title: 'ExpressDelivery' },
  { key: 'cash', title: 'Cash' },
  { key: 'transfer', title: 'Transfer' },
  { key: 'pos', title: 'Pos' },
  { key: 'refCode', title: 'PaymentTypeReference' },
  { key: 'declaredValue', title: 'DeclarationOfValueCheck' },
  { key: 'custType', title: 'CustomerType' },
  { key: 'user', title: 'UserName' },
  { key: 'date', title: 'DateCreated' },
  // { key: 'shipmentType', title: 'Shipment Type' },
];

export const dailySalesCreateTableData = (data: any, serialno: any) => {

  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, index: number) => {
      const serialNumber = index + 1;
console.log(item,"dailysales");

      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + serialNumber}.
          </span>
        ),
        waybill: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item.Waybill}
            </span>
          </div>
        ),
        destination: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.ReceiverAddress ? item?.ReceiverAddress : item?.DestinationServiceCentreName}
            </span>
          </div>
        ),
        grandTotal: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {`₦${formatMoney(item.GrandTotal)}`}
            </span>
          </div>
        ),
        kg: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ApproximateItemsWeight?.toFixed(2)}KG
            </span>
          </div>
        ),
        status: (
          <div className="w-full flex justify-center items-center p-1 rounded-[2rem] px-2" style={{ backgroundColor: item.PaymentStatus === 1 ? "#CDF4DE" : "#FFF7E2" }}>
            <span
              className={`text-txt_087_normal capitalize ${item.PaymentStatus === 1
                ? 'text-col_219653 px-4'
                : 'text-yellow-500 px-2'
                }  py-[2px] rounded-[2rem]`}
            >
              {paymentStatus[item.PaymentStatus]}
            </span>
          </div>
        ),

        // others: (
        //   <>  {item.PaymentStatus === 0 &&
        //     <div className="w-ful flex items-start ">
        //       <span className={`text-txt_087_normal capitalize border border-col_219653 text-col_219653 px-4 py-[2px] rounded-[2rem]`}>
        //         <button onClick={item?.others?.viewPaymnetPending}>Pay</button>
        //       </span>
        //     </div>
        //   }
        //   </>),
        paymentType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {paymentTypes[item.PaymentType] || "--"}
            </span>
          </div>
        ),



        deliveryType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ExpressDelivery ? 'Express' : 'Normal'}
            </span>
          </div>
        ),
        cash: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.Cash ? `₦${formatMoney(item?.Cash)}` : "₦0"}
            </span>
          </div>
        ),
        transfer: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.Transfer ? `₦${formatMoney(item.Transfer)}` : 'nil'}
            </span>
          </div>
        ),
        pos: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {item.Pos ? `₦${formatMoney(item?.Pos)}` : 'nil'}
            </span>
          </div>
        ),
        refCode: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {/* {item.PaymentTypeReference} */}
              {item?.ReferenceCode ?? "--"}
            </span>
          </div>
        ),
        declaredValue: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DeclarationOfValueCheck}
            </span>
          </div>
        ),
        custType: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {customerType[item.CustomerType]}
            </span>
          </div>
        ),
        user: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.UserName}
            </span>
          </div>
        ),
        date: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {format(item.DateCreated, "dd MMMM yyyy hh:mm a")}
            </span>
          </div>
        ),
        viewIcon: (
          <div className="flex items-center justify-end ">
            <img
              src={search_plus}
              alt="viewShipmentetail-icon"
              className="cursor-pointer hover:opacity-100 opacity-50 min-w-[1.3rem]"
            />
            {/* <DialogBox isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} /> */}
          </div>
        ),

        others: (
          <>  {item.PaymentStatus === 0 ?
            <div className="w-ful flex items-start cursor-pointer  ">
              <span className={`text-txt_087_normal capitalize border border-col_219653 text-col_219653 px-4 py-[2px] rounded-[2rem]`}>
                <button onClick={item?.others?.viewPaymnetPending}>Pay</button>
              </span>
            </div> : <img
              src={print_dark_icon}
              alt=""
              className="cursor-pointer hover:opacity-100 opacity-50 min-w-[1.3rem]"
              onClick={item?.others?.printInvoice}
            />
          }
          </>),
        // printIcon: (

        //   <>
        //     {item.PaymentStatus !== 0 &&  <img
        //       src={print_dark_icon}
        //       alt=""
        //       className="cursor-pointer hover:opacity-100 opacity-50 min-w-[1.3rem]"
        //     />
        //     }
        //   </>
        // ),

      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const demurrageReportTableHeader = [
  { key: 'waybill', title: 'WAYBILL' },
  { key: 'serivceCenter', title: 'SERVICE CENTRE' },
  { key: 'amount', title: 'AMOUNT' },
  { key: 'creditType', title: 'CREDIT TYPE' },
  { key: 'deliveryType', title: 'DELIVERY TYPE' },
  { key: 'status', title: 'STATUS' },
  { key: 'serviceType', title: 'SERVICE TYPE' },
  { key: 'paymentType', title: 'PAYMENT TYPE' },
  { key: 'paymentTypeReference', title: 'PAYMENT REF' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'user', title: 'USER' },
  { key: 'date', title: 'DATE' },
  // { key: 'viewIcon', title: '' },
  // { key: 'printIcon', title: '' },
];

export const demmurrageReportCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {item.Waybill}
            </span>
          </div>
        ),
        serivceCenter: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.ServiceCentre}
            </span>
          </div>
        ),
        amount: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_219653 capitalize`}>
              {`${item.CurrencySymbol}${formatMoney(item.Amount)}`}

            </span>
          </div>
        ),
        creditType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {creditDebitType[item.CreditDebitType]}
            </span>
          </div>
        ),
        deliveryType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {deliveryType[item.DeliveryType]}
            </span>
          </div>
        ),
        status: (
          <div className="w-full flex items-start">
            <span
              className={`text-txt_087_normal capitalize border ${item.PaymentStatus === 1
                ? 'border-col_219653 text-col_219653 px-4'
                : 'border-col_c2c7cc text-col_c2c7cc px-2'
                }  py-[2px] rounded-[2rem]`}
            >
              {paymentStatus[item.PaymentStatus]}
            </span>
          </div>
        ),
        serviceType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {PaymentServiceType[item.PaymentServiceType]}
            </span>
          </div>
        ),
        paymentType: (
          <div className="w-full flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {paymentTypes[item.PaymentType]}
            </span>
          </div>
        ),

        paymentTypeReference: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal capitalize`}>
              {item?.paymentTypeReference ? "" : "nill"}
            </span>
          </div>
        ),
        description: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal  capitalize`}>
              {item.Description}
            </span>
          </div>
        ),
        user: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.UserName}
            </span>
          </div>
        ),
        date: (
          <div className="w-ful flex items-start">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {format(item.DateOfEntry, "dd MMMM yyyy hh:mm a")}
            </span>
          </div>
        ),
        // viewIcon: (
        //   <div className="flex items-center justify-end ">
        //     <img
        //       src={search_plus}
        //       alt="viewShipmentetail-icon"
        //       className="cursor-pointer hover:opacity-100 opacity-50 min-w-[1.3rem]"
        //     />

        //     {/* <DialogBox isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} /> */}
        //   </div>
        // ),
        // printIcon: (
        //   <img
        //     src={print_dark_icon}
        //     alt=""
        //     className="cursor-pointer hover:opacity-100 opacity-50 min-w-[1.3rem]"
        //   />
        // ),

      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

// export const deliveryLocationsTableHeader = [
//   { key: 'location', title: 'location' },
//   { key: 'homeDeliveryService', title: 'home Delivery Service' },
// ];

// export const deliveryServiceCreateTableData = (data: any) => {
//   const dataToMap = data;
//   if (Array.isArray(dataToMap)) {
//     const outputArray: any = [];
//     dataToMap.map((item: any, i: number) => {
//       const show = {
//         serial: (
//           <div>
//             <span className={`text-txt_087_normal text-col_525252`}>
//               {i + 1}.
//             </span>
//           </div>
//         ),
//         location: (
//           <div className="max-w-[6rem] flex items-start">
//             <span className={`text-txt_087_normal text-col_525252`}>
//               {item.location}
//             </span>
//           </div>
//         ),
//         homeDeliveryService: (
//           <div className="max-w-[10rem] flex items-start">
//             <span
//               className={`text-txt_087_normal capitalize border ${
//                 item.homeDeliveryService === 'available'
//                   ? 'border-col_219653 text-col_219653 px-4'
//                   : 'border-col_e84e40 text-col_e84e40 px-2'
//               }  py-[2px] rounded-[2rem]`}
//             >
//               {item.homeDeliveryService}
//             </span>
//           </div>
//         ),
//       };
//       outputArray.push(show);
//       return true;
//     });
//     return outputArray;
//   }
// };

export const overdueTabeHeader = [
  { key: 'dateCreated', title: 'date Created' },
  { key: 'waybill', title: 'waybill' },
  { key: 'overdueCount', title: 'overdue Count' },
  { key: 'discount', title: 'discount' },
  { key: 'amount', title: 'amount' },
  { key: 'track', title: '' },
];

export const overdueShipmentCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.waybill}
            </span>
          </div>
        ),
        overdueCount: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.overdueCount}
            </span>
          </div>
        ),
        discount: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.discount}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.amount}
            </span>
          </div>
        ),
        track: (
          <div className="flex items-center gap-2 ">
            <img src={search_plus} alt="" className={`cursor-pointer`} />
            <Button
              text="track"
              className={`rounded-[0.5rem] py-1 px-3 flex items-center justify-center text-txt_075_400 text-col_fff bg-col_e84e40`}
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

interface IPackingListProps {
  serial: number;
  location: string;
  homeDeliveryService: string;
}

export const packingListTableHeader: ColumnDef<IPackingListProps>[] = [
  {
    header: 'S/N',
    cell: (row) => row.renderValue(),
    accessorKey: 'serial',
  },
  {
    header: () => HeaderComponent({ headerTitle: 'Waybill' }),
    cell: (row) => row.renderValue(),
    accessorKey: 'waybill',
  },
  {
    header: '',
    cell: (row) => row.renderValue(),
    accessorKey: 'others',
  },
];

export const packingListCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_525252 self-start">
              {item.waybill}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-2">
            <img src={search_plus} alt="" className="cursor-pointer" />
            <img src={print_dark_icon} alt="" className="cursor-pointer" />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const expenditureTableHeader = [
  { key: 'expenseType', title: 'expense Type' },
  { key: 'amount', title: 'amount' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: 'description', title: 'description' },
  { key: 'createdBy', title: 'created By' },
  { key: 'date', title: 'date' },
];

export const expenditureCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        expenseType: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_525252 self-start">
              {item.waybill}
            </span>
          </div>
        ),
        amount: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_219653 self-start">
              {item.amount}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_525252 self-start">
              {item.serviceCenter}
            </span>
          </div>
        ),
        description: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_525252 self-start">
              {item.description}
            </span>
          </div>
        ),
        createdBy: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_525252 self-start">
              {item.createdBy}
            </span>
          </div>
        ),
        date: (
          <div className={`flex item-start`}>
            <span className="text-txt_087_normal text-col_525252 self-start">
              {item.date}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const ecomsOverdueShipmentTabeHeader = [
  { key: 'waybill', title: 'WAYBILL' },
  { key: 'departureCenter', title: 'DEPARTURE CENTRE' },
  { key: 'destinationCenter', title: 'DESTINATION CENTRE' },
  { key: 'dateCreated', title: 'DATE CREATED' },
  { key: 'approveShipmentReturn', title: '' },
  { key: 'others', title: '' },
];
export const ecomsOverdueShipmentCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        departureCenter: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.departureCenter}
            </span>
          </div>
        ),
        destinationCenter: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.destinationCenter}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.dateCreated}
            </span>
          </div>
        ),
        approveShipmentReturn: (
          <div className="max-w-[13rem] flex items-center justify-center bg-col_219653 py-2 rounded-[1rem]">
            <Button
              prefixIcon={white_check}
              text="Approve Shipment Return"
              onClick={item.approveShipmentReturn.assign}
              className="py-1 px-2 text-white text-sm font-medium capitalize"
            />
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img src={search_plus} alt="" className="cursor-pointer" />
            <img src={print_dark_icon} alt="" className="cursor-pointer" />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const bankProcessingOrderTabeHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'amount', title: 'amount' },
  { key: 'status', title: 'status' },
];
export const bankProcessingOrderCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Waybill}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {formatMoney(item.Amount)}
            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_fff bg-col_76818e py-1 px-2 capitalize rounded-[0.5rem] `}
            >
              {paymentStatus[item.Status] || "--"}

            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const bankDepositOrderTableHeader = [
  { key: 'dateCreated', title: 'date Created' },
  { key: 'reference', title: 'reference' },
  { key: 'staffName', title: 'staff Name' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: 'bank', title: 'bank' },
  { key: 'status', title: 'status' },
  { key: 'depositDate', title: 'deposit Date' },
];

export const bankDepositOrderTableHeaderApiParams = [
  { key: 'dateCreated', title: 'DateCreated' },
  { key: 'reference', title: 'Reference' },
  { key: 'staffName', title: 'StaffName' },
  { key: 'serviceCenter', title: 'ServiceCenter' },
  { key: 'bank', title: 'Bank' },
  { key: 'status', title: 'Status' },
  { key: 'depositDate', title: 'DepositDate' },
];


export const bankDepositOrderCreateTableData = (data: any, serialno: any) => {

  const dataToMap = data;

  const handleCopy = (code: string) => {
    if (code) {
      navigator.clipboard.writeText(code)
        .then(() => {
          toast.success("Copied!")
          // Reset after 2 seconds
        })
        .catch(err => console.error("Failed to copy: ", err));
    }
  };

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      // console.log(item, "itemitemitem")
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + i + 1}.
          </span>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {format(item.DateCreated, "dd MMMM yyyy hh:mm a") || "--"}
              {/* {item?.DateCreated} */}
            </span>
          </div>
        ),
        reference: (
          <div className="flex items-center gap-1 ">
            <span
              className={`cursor-pointer text-txt_087_normal text-col_525252 capitalize`}
            >
              {item.Code || "--"}
            </span>
            <img onClick={() => handleCopy(item.Code)} src={copy_dark} alt="" className="cursor-pointer" />
          </div>
        ),
        staffName: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.FirstName || item.LastName ? `${item.FirstName} ${item.LastName}` : "--"}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.ScName || "--"}
            </span>
          </div>
        ),
        bank: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
            >
              {depostiStatus[item.BankName] || "--"}
            </span>
          </div>
        ),
        status: (
          <div className={`flex items-center `}>
            <span
              className={`text-col_fff text-txt_087_normal capitalize ${statusColors[item?.Status]} px-2 py-1 rounded-[0.5rem] `}
            >
              {paymentStatus[item.Status] || "--"}

              {/* {item.status} */}
            </span>
          </div>
        ),
        depositDate: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 p-1 capitalize py-[2px]  `}
            >

              {format(item.DateAndTimeOfDeposit, "dd MMMM yyyy hh:mm a") || "--"}

              {/* {item.DateAndTimeOfDeposit} */}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

// export const validateWaybillTableHeader = [
//   { key: 'waybill', title: 'referenwaybillce' },
//   { key: 'reference', title: 'reference' },
//   { key: 'amount', title: 'amount' },
//   { key: 'waybillSettled', title: 'waybill Settled' },
//   { key: 'paymentSuccessful', title: 'payment Successful' },
//   { key: 'paymentType', title: 'payment Type' },
//   { key: 'phoneNumber', title: 'phone Number' },
//   { key: 'email', title: 'email' },
//   { key: 'networkProvider', title: 'network Provider' },
//   { key: 'status', title: 'status' },
//   { key: 'response', title: 'response' },
// ];

// export const validateWaybillCreateTableData = (data: any) => {
//   const dataToMap = data;
//   if (Array.isArray(dataToMap)) {
//     const outputArray: any = [];
//     dataToMap.map((item: any, i: number) => {
//       const show = {
//         serial: (
//           <div className="flex items-center ">
//             <span className={`text-txt_087_normal text-col_525252`}>
//               {i + 1}.
//             </span>
//           </div>
//         ),
//         waybill: (
//           <div className="flex items-center ">
//             <span className={`text-txt_087_normal text-col_525252 capitalize`}>
//               {item.waybill}
//             </span>
//           </div>
//         ),
//         reference: (
//           <div className="flex items-center gap-1 ">
//             <span
//               className={`cursor-pointer text-txt_087_normal text-col_525252 capitalize`}
//             >
//               {item.reference}
//             </span>
//             <img src={copy_dark} alt="" className="cursor-pointer" />
//           </div>
//         ),
//         amount: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
//             >
//               {item.amount}
//             </span>
//           </div>
//         ),
//         waybillSettled: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
//             >
//               {item.waybillSettled}
//             </span>
//           </div>
//         ),
//         paymentSuccessful: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_525252 capitalize py-[2px] rounded-[2rem] `}
//             >
//               {item.paymentSuccessful}
//             </span>
//           </div>
//         ),
//         paymentType: (
//           <div
//             className={`flex items-center justify-center text-col_525252 py-2 px-1 rounded-[0.5rem]`}
//           >
//             <span className={`text-col_fff text-txt_087_normal capitalize `}>
//               {item.paymentType}
//             </span>
//           </div>
//         ),
//         phoneNumber: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_525252 p-1 capitalize py-[2px]  `}
//             >
//               {item.phoneNumber}
//             </span>
//           </div>
//         ),
//         email: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_525252 p-1 capitalize py-[2px]  `}
//             >
//               {item.email}
//             </span>
//           </div>
//         ),
//         networkProvider: <div className="flex items-center "></div>,
//         status: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_525252 p-1 capitalize py-[2px]  `}
//             >
//               {item.status}
//             </span>
//           </div>
//         ),
//         response: (
//           <div className="flex items-center ">
//             <span
//               className={`text-txt_087_normal text-col_525252 p-1 capitalize py-[2px]  `}
//             >
//               {item.response}
//             </span>
//           </div>
//         ),
//       };
//       outputArray.push(show);
//       return true;
//     });
//     return outputArray;
//   }
// };

export const transferManagementTabeHeader = [
  { key: 'amount', title: 'amount' },
  { key: 'senderAcc', title: 'senderAcc' },
  { key: 'senderName', title: 'sender Name' },
  { key: 'status', title: 'status' },
  { key: 'senderBank', title: 'sender Bank Name' },
  { key: 'reference', title: 'reference' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: "dateCreated", title: "date created" }
];
export const transferManagementCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        senderAcc: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.OriginatorAccountNumber}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              ₦{formatMoney(item.Amount)}
            </span>
          </div>
        ),
        senderName: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 py-1 px-2 capitalize rounded-[0.5rem] `}
            >
              {item.OriginatorName}
            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span
              className={`border px-2 py-[3px] ${item.status === 'success'
                ? 'border-col_219653 text-col_219653'
                : 'border-col_525252 text-col_525252'
                } text-txt_087_normal py-1 px-2 capitalize rounded-[2rem] `}
            >
              {item.Status}
            </span>
          </div>
        ),
        senderBank: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 py-1 px-2 capitalize rounded-[0.5rem] `}
            >
              {item.BankName}
            </span>
          </div>
        ),
        reference: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 py-1 px-2 capitalize rounded-[0.5rem] `}
            >
              {item.PaymentReference}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 py-1 px-2 capitalize rounded-[0.5rem] `}
            >
              {item.ServiceCentreName ? item.ServiceCentreName : "--"}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 py-1 px-2 capitalize rounded-[0.5rem] `}
            >
              {item.DateCreated && format(item.DateCreated, "dd MMMM yyyy hh:mm a") || "--"}
            </span>
          </div>
        ),

      };

      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

// interface Props {
//   serial: string;
//   waybill: string;
//   departure: string;
//   destinationCenter: string;
//   deliveryType: string;
//   shipmentType: string;
//   date: string;
// }
export const groupShipmentViewTableHeader = [
  { key: 'waybill', title: 'GROUP WAYBILL' },
  { key: 'departure', title: 'DEPARTURE' },
  { key: 'destinationCenter', title: 'DESTINATION CENTER' },
  { key: 'deliveryType', title: 'DELIVERY TYPE' },
  { key: 'shipmentType', title: 'SHIPMENT TYPE' },
  { key: 'date', title: 'DATE' },
  { key: 'others', title: '' },
];

export const groupShipmentViewCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.GroupWaybillCode}
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.column}
            </span>
          </div>
        ),
        departure: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DepartureServiceCentre}
            </span>
          </div>
        ),
        destinationCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DestinationServiceCentre}
            </span>
          </div>
        ),
        deliveryType: (
          <div className="flex items-center ">
            <div
              className={`flex items-center gap-[3px] border ${item.ExpressDelivery === true
                ? 'text-[#e36414] bg-[#FBDADA]'
                : 'text-[#22577a] bg-[#CDF4DE]'
                } rounded-[2rem] px-4 py-1`}
            >
              {item?.ExpressDelivery === true && (
                <img src={go_faster} alt="" />
              )}
              <span
                className={`text-txt_087_normal ${item?.ExpressDelivery === true
                  ? 'text-[#e36414] bg-[#FBDADA]'
                  : 'text-[#22577a] bg-[#CDF4DE]'
                  } capitalize`}
              >
                {item?.ExpressDelivery ? "Go Faster" : "Go Standard"}
              </span>
            </div>
          </div>
        ),
        shipmentType: (
          <div className="flex items-center ">
            <div
              className={`flex items-center gap-[3px] border ${item.IsBulky === false
                ? 'text-[#22577a] bg-[#CDF4DE]'
                : 'text-black bg-[#8F8F8F]'
                } rounded-[2rem] px-4 py-1`}
            >
              {/* {item.shipmentType === 'bulky' && <img src={bulky} alt="" />} */}
              <span
                className={`text-txt_087_normal ${item.IsBulky === false
                  ? 'text-[#22577a] bg-[#CDF4DE]'
                  : 'text-black bg-[#8F8F8F]'
                  } capitalize p-0`}
              >
                {item?.IsBulky ? "Bulky" : "Normal"}
              </span>
            </div>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DateCreated ? format(item.DateCreated, "dd MMMM yyyy hh:mm a") : ""}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item?.others?.shipmentDetailsModal}
            />
            {/* <img
              src={print_dark_icon}
              alt=""
              className="cursor-pointer"
            // onClick={item.others.print}
            /> */}
            <img src={table_plus_icon} onClick={item?.others?.viewDetails} alt="" className="cursor-pointer" />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const addGroupShipmentView_TableHeader = [
  { key: 'groupWaybill', title: 'WAYBILL NUMBER' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'addItem', title: '' },
];



export const addGroupShipmentView_CreateTableData = (data: any) => {
  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {

      const destinationText = item.DestinationServiceCentre;
      const shortenedDestination = destinationText?.length > 20
        ? destinationText.substring(0, 20) + "..."
        : destinationText;
      const EditArray = item?.others?.itemNew
      const isItemSelected = EditArray?.some(
        (idItem: any) => idItem?._id === item?._id
      );



      const handleAddClick = () => {
        // Add or remove the item from idShipment dynamically
        if (isItemSelected) {
          item.others.idShipment = item?.others?.itemNew?.filter(
            (idItem: any) => idItem?._id !== item?._id
          );
        } else {
          item?.others?.idShipment?.push({ _id: item?._id });
        }
      };

      const show = {

        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Waybill}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center">
            <span
              className={`text-txt_087_normal text-col_66bb6a capitalize truncate max-w-[120px]`}
              title={shortenedDestination}
            >
              {item.DestinationServiceCentre}
            </span>
          </div>
        ),
        addItem: (
          <div className="flex items-center " onClick={() => { item?.others?.viewDetails(item?._id); handleAddClick() }} >

            <span
              className={`cursor-pointer text-txt_087_normal px-3 py-1 rounded-[62.4375rem] capitalize ${isItemSelected
                ? "bg-slate-400 text-col_fff"
                : "bg-[#219653] text-col_fff"
                }`}
            >
              {isItemSelected ? 'Added' : 'Add'}
            </span>
          </div>
        ),
      };
      outputArray?.push(show);
      return true;
    });
    return outputArray;
  }
};



export const addManifest_TableHeader = [
  { key: 'groupWaybill', title: 'GROUP WAYBILL' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'addItem', title: '' },
];



export const addManifestView_CreateTableData = (data: any) => {
  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {

      const destinationText = item.DestinationServiceCentre;
      const shortenedDestination = destinationText?.length > 20
        ? destinationText.substring(0, 20) + "..."
        : destinationText;
      const EditArray = item?.others?.itemNew
      const isItemSelected = EditArray?.some(
        (idItem: any) => idItem?._id === item?._id
      );



      const handleAddClick = () => {
        // Add or remove the item from idShipment dynamically
        if (isItemSelected) {
          item.others.idShipment = item?.others?.itemNew?.filter(
            (idItem: any) => idItem?._id !== item?._id
          );
        } else {
          item?.others?.idShipment?.push({ _id: item?._id });
        }
      };

      const show = {

        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.GroupWaybillCode}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center">
            <span
              className={`text-txt_087_normal text-col_66bb6a capitalize truncate max-w-[120px]`}
              title={shortenedDestination}
            >
              {item.DestinationServiceCentre}
            </span>
          </div>
        ),
        addItem: (
          <div className="flex items-center " onClick={() => { item?.others?.viewDetails(item?._id); handleAddClick() }} >

            <span
              className={`cursor-pointer text-txt_087_normal px-3 py-1 rounded-[62.4375rem] capitalize ${isItemSelected
                ? "bg-slate-400 text-col_fff"
                : "bg-[#219653] text-col_fff"
                }`}
            >
              {isItemSelected ? 'Added' : 'Add'}
            </span>
          </div>
        ),
      };
      outputArray?.push(show);
      return true;
    });
    return outputArray;
  }
};




export const saveGroupShipmentView_TableHeader = [
  { key: 'groupWaybill', title: 'GROUP WAYBILL' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'deleteItem', title: '' },
];

export const saveGroupShipmentView_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {

      const destinationText = item.DestinationServiceCentre;
      const shortenedDestination = destinationText?.length > 20
        ? destinationText.substring(0, 20) + "..."
        : destinationText;
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.manifest === "manifestData" ? item?.GroupWaybillCode : item.Waybill}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center">
            <span
              className={`text-txt_087_normal text-col_66bb6a capitalize truncate max-w-[120px]`}
              title={shortenedDestination}
            >
              {item.DestinationServiceCentre}
            </span>
          </div>
        ),
        deleteItem: (
          <div className="flex items-center " >
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};



export const waybillListGroupShipmentView_TableHeader = [
  { key: 'groupWaybill', title: 'GROUP WAYBILL' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'deleteItem', title: '' },
];

export const waybillListGroupShipmentView_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {

      const destinationText = item.DestinationServiceCentre;
      const shortenedDestination = destinationText.length > 20
        ? destinationText.substring(0, 20) + "..."
        : destinationText;
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.WaybillNumber}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center">
            <span
              className={`text-txt_087_normal text-col_66bb6a capitalize truncate max-w-[120px]`}
              title={shortenedDestination}
            >
              {item?.DestinationServiceCentre}
            </span>
          </div>
        ),
        deleteItem: (
          <div className="flex items-center " >
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};




export const addMovementManifest_TableHeader = [
  { key: 'groupWaybill', title: 'MANIFEST CODE' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'addItem', title: '' },
];


export const addMovementManifest_CreateTableData = (data: any) => {
  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {

      const destinationText = item.DestinationServiceCentre;
      const shortenedDestination = destinationText?.length > 20
        ? destinationText.substring(0, 20) + "..."
        : destinationText;
      const EditArray = item?.others?.itemNew
      const isItemSelected = EditArray?.some(
        (idItem: any) => idItem?._id === item?._id
      );

      const handleAddClick = () => {
        // Add or remove the item from idShipment dynamically
        if (isItemSelected) {
          item.others.idShipment = item?.others?.itemNew?.filter(
            (idItem: any) => idItem?._id !== item?._id
          );
        } else {
          item?.others?.idShipment?.push({ _id: item?._id });
        }
      };

      const show = {

        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ManifestCode}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center">
            <span
              className={`text-txt_087_normal text-col_66bb6a capitalize truncate max-w-[120px]`}
              title={shortenedDestination}
            >
              {item.DestinationServiceCentre}
            </span>
          </div>
        ),
        addItem: (
          <div className="flex items-center " onClick={() => { item?.others?.viewDetails(item?._id); handleAddClick() }} >

            <span
              className={`cursor-pointer text-txt_087_normal px-3 py-1 rounded-[62.4375rem] capitalize ${isItemSelected
                ? "bg-slate-400 text-col_fff"
                : "bg-[#219653] text-col_fff"
                }`}
            >
              {isItemSelected ? 'Added' : 'Add'}
            </span>
          </div>
        ),
      };
      outputArray?.push(show);
      return true;
    });
    return outputArray;
  }
};



export const saveMovementMani_TableHeader = [
  { key: 'groupWaybill', title: 'MANIFEST CODE' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'deleteItem', title: '' },
];

export const saveMovementManifest_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {

      const destinationText = item.DestinationServiceCentre;
      const shortenedDestination = destinationText?.length > 20
        ? destinationText.substring(0, 20) + "..."
        : destinationText;
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.ManifestCode}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center">
            <span
              className={`text-txt_087_normal text-col_66bb6a capitalize truncate max-w-[120px]`}
              title={shortenedDestination}
            >
              {item.DestinationServiceCentre}
            </span>
          </div>
        ),
        deleteItem: (
          <div className="flex items-center " >
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};






export const gatewayActivity_TableHeader = [
  { key: 'manifestNumber', title: 'manifest Number' },
  { key: 'waybill', title: 'waybill' },
  { key: 'departure', title: 'departure' },
  { key: 'destination', title: 'destination' },
  { key: 'dateCreated', title: 'date Created' },
  { key: 'dateScannedArrived', title: 'date Scanned Arrived' },
];

export const gatewayActivity_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const manifest_TableHeader = [
  { key: 'manifest', title: 'MANIFEST' },
  { key: 'manifestType', title: 'MANIFEST TYPE' },
  { key: 'dateMapped', title: 'DATE MAPPED' },
  { key: 'dispatched', title: 'DISPATCHED' },
  { key: 'dispatcher', title: 'DISPATCHER' },
  { key: 'received', title: 'RECEIVED' },
  { key: 'deliveryType', title: 'DELIVERY TYPE' },
  { key: 'shipmentType', title: 'SHIPMEMNT TYPE' },
  { key: 'receiver', title: 'RECEIVER' },
  // { key: 'departure', title: 'DEPARTURE' },
  { key: 'destination', title: 'DESTINATION' },


  { key: 'others', title: '' },
];

// export const manifest_TableHeader: ColumnDef<Props>[] = [
//   {
//     header: 'S/N',
//     cell: (row) => row.renderValue(),
//     accessorKey: 'serial',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'manifest' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'manifest',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'manifest type' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'manifestType',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'departure' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'departure',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'date mapped' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'dateMapped',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'dispatched' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'dispatched',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'dispatcher' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'dispatcher',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'received' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'received',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'delivery type' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'deliveryType',
//   },
//   {
//     header: () => HeaderComponent({ headerTitle: 'shipment type' }),
//     cell: (row) => row.renderValue(),
//     accessorKey: 'shipmentType',
//   },
// ];

export const manifest_CreateTableData = (data: any, serialno: any) => {
  const dataToMap = data;

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + i + 1}.
          </span>
        ),
        manifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.ManifestCode}
            </span>
          </div>
        ),
        manifestType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {manifestTypeEnum[item?.ManifestType] ?? "--"}
            </span>
          </div>
        ),
        departure: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DepartureServiceCentre ?? "--"}
            </span>
          </div>
        ),
        dateMapped: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DateCreated ? format(item.DateCreated, "dd MMMM yyyy hh:mm a") : ""}
            </span>
          </div>
        ),
        dispatched: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_525252 capitalize
                ${item?.IsDispatched
                  ? 'text-[#e36414] bg-[#CDF4DE] rounded-[2rem] px-4 py-1'
                  : 'text-[#22577a] bg-[#FBDADA] rounded-[2rem] px-4 py-1'
                }`
              }
            >
              {item?.IsDispatched ? "true" : "false"}
            </span>
          </div>
        ),
        dispatcher: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.Dispatcher ? item?.Dispatcher : "--"}
            </span>
          </div>
        ),
        received: (
          <div className="flex items-center ">

            <span
              className={`text-txt_087_normal text-col_525252 capitalize
                ${item?.IsReceived
                  ? 'text-[#e36414] bg-[#CDF4DE] rounded-[2rem] px-4 py-1'
                  : 'text-[#22577a] bg-[#FBDADA] rounded-[2rem] px-4 py-1'
                }`}
            >
              {item?.IsReceived ? "true" : "false"}
            </span>
          </div>
        ),
        deliveryType: (
          <div className="flex items-center ">
            <div
              className={`flex items-center gap-[3px] border ${item.ExpressDelivery === true
                ? 'text-[#e36414] bg-[#FBDADA]'
                : 'text-[#22577a] bg-[#CDF4DE]'
                } rounded-[2rem] px-4 py-1`}
            >
              {item?.ExpressDelivery === true && (
                <img src={go_faster} alt="" />
              )}
              <span
                className={`text-txt_087_normal ${item?.ExpressDelivery === true
                  ? 'text-[#e36414] bg-[#FBDADA]'
                  : 'text-[#22577a] bg-[#CDF4DE]'
                  } capitalize`}
              >
                {item?.ExpressDelivery ? "Go Faster" : "Go Standard"}
              </span>
            </div>
          </div>

        ),
        shipmentType: (
          <div className="flex items-center ">
            <div
              className={`flex items-center gap-[3px] border ${item.IsBulky === false
                ? 'text-[#22577a] bg-[#CDF4DE]'
                : 'text-black bg-[#8F8F8F]'
                } rounded-[2rem] px-4 py-1`}
            >
              {/* {item.shipmentType === 'bulky' && <img src={bulky} alt="" />} */}
              <span
                className={`text-txt_087_normal ${item.IsBulky === false
                  ? 'text-[#22577a] bg-[#CDF4DE]'
                  : 'text-black bg-[#8F8F8F]'
                  } capitalize p-0`}
              >
                {item?.IsBulky ? "Bulky" : "Normal"}
              </span>
            </div>
          </div>
        ),

        receiver: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {"--"}
            </span>
          </div>
        ),

        destination: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DestinationServiceCentre
                ? item?.DestinationServiceCentre
                : "--"}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer opacity-70 hover:opacity-100"
              onClick={item?.others?.viewDetails}
            />
            {item?.IsDispatched &&
              <img
                src={print_dark_icon}
                alt=""
                className="cursor-pointer opacity-70 hover:opacity-100"
                onClick={item?.others?.invoice}
              />
            }

            {!item?.IsDispatched &&
              <button className="text-[#ffff] bg-[#66BB6A] p-2 text-sm font-extralight rounded-lg" onClick={item?.others?.registerDispatch}>
                Register Dispatch
              </button>
            }


            {/* <img
              src={show_password}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
              onClick={item?.others?.viewWaybillReport}
            /> */}
            {!item?.IsDispatched &&
              <img
                src={table_plus_icon}
                alt=""
                className="w-6 cursor-pointer opacity-70 hover:opacity-100"
                onClick={item?.others?.addGroupEdit}
              />
            }
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const manifestDetails_TableHeader = [
  { key: 'groupWaybill', title: 'GROUP WAYBILL' },
  { key: 'waybills', title: 'WAYBILLS' },
  { key: 'destination', title: 'DEST' },
  { key: 'dept', title: 'DEPT' },
  { key: 'weight', title: 'WEIGHT' },
  { key: 'value', title: 'VALUE' },
  { key: 'declaredValue', title: 'DECLARED VALUE' },
  { key: 'paymentMethod', title: 'PAYMENT METHOD' },
  { key: 'paymnetStatus', title: 'PAYMENT STATUS' },
  { key: 'others', tittle: "" }
];

export const manifestDetails_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.GroupWaybillNumber}
            </span>
          </div>
        ),
        waybills: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {formatMoney(item.Waybill)}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.DepartureServiceCentre}
            </span>
          </div>
        ),
        dept: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.DestinationServiceCentre}
            </span>
          </div>
        ),
        weight: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {formatMoney(item.Weight)}
            </span>
          </div>
        ),
        value: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.Value ? formatMoney(item.Value) : "-"}
            </span>
          </div>
        ),
        declaredValue: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {formatMoney(item.DeclaredValue)}
            </span>
          </div>
        ),
        paymentMethod: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {item.PaymentMethod ? paymentTypes[item.PaymentMethod] : "-"}
            </span>
          </div>
        ),
        paymnetStatus: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >

              {paymentStatus[item.PaymentStatus]}
              {/* {formatMoney(item.PaymentStatus)} */}
            </span>
          </div>
        ),

        // others: (
        //   <div className="flex items-center justify-end gap-4 pr-[1rem]">

        //     <img src={red_delete_icon} onClick={item.others?.delete} alt="" className="cursor-pointer" />
        //   </div>
        // ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const manifestWaybillReport_TableHeader = [
  { key: 'groupWaybill', title: 'GROUP WAYBILL' },
  { key: 'waybill', title: 'WAYBILL' },
  { key: 'departure', title: 'DEPARTURE' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'weight', title: 'WEIGHT' },
  { key: 'value', title: 'VALUE' },
  { key: 'declaredvalue', title: 'DECLARED VALUE' },
  { key: 'paymentMethod', title: 'PAYMENT METHOD' },
  { key: 'paymentStatus', title: 'PAYMENT STATUS' },
  { key: 'others', title: '' },
];

export const manifestWaybillReport_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        groupWaybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.groupWaybill}
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        departure: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departure}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destination}
            </span>
          </div>
        ),
        weight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.weight}
            </span>
          </div>
        ),
        value: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.value}
            </span>
          </div>
        ),
        declaredvalue: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.declaredvalue}
            </span>
          </div>
        ),
        paymentMethod: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.paymentMethod}
            </span>
          </div>
        ),
        paymentStatus: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.paymentStatus}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const movementManifest_TableHeader = [
  { key: 'movementManifest', title: 'MOVEMENT MANIFEST' },
  { key: 'dateCreated', title: 'DATE CREATED' },
  { key: 'status', title: 'STATUS' },
  { key: 'from', title: 'FROM' },
  { key: 'driverCode', title: 'DRIVER CODE' },
  { key: 'destinationCode', title: 'DESTINATION CODE' },
  { key: 'destinationStation', title: 'DESTINATION STATION' },
  { key: 'others', title: '' },
];

export const movementManifest_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];      
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        movementManifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.MovementManifestCode}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DateCreated && format(item.DateCreated, "dd MMMM yyyy hh:mm a") || "--"}            </span>
          </div>
        ),
        status: (
          <div className={`flex items-center `}>
            <span
              className={`text-col_fff text-txt_087_normal capitalize ${MovementStatus[item.MovementStatus] === 'InProgress'
                ? 'bg-col_f2c94c'
                : 'bg-col_219653'
                } px-2 py-1 rounded-[0.5rem] `}
            >
              {MovementStatus[item.MovementStatus]}
            </span>
          </div>
        ),
        from: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.DepartureServiceCentre}
            </span>
          </div>
        ),
        driverCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DriverCode}
            </span>
          </div>
        ),
        destinationCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ReceiverCode}
            </span>
          </div>
        ),
        destinationStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DestinationStation}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              onClick={item.others.viewDetails}
              src={search_plus}
              alt=""
              className="cursor-pointer"
            />
            {item?.IsDispatched &&
              <img
                src={print_dark_icon}
                alt=""
                className="cursor-pointer opacity-70 hover:opacity-100"
                onClick={item?.others?.invoice}
              />
            }

            {!item?.IsDispatched &&
              <button className="text-[#ffff] bg-[#66BB6A] p-2 text-sm font-extralight rounded-lg" onClick={item?.others?.registerDispatch}>
                Register Dispatch
              </button>
            }

            {/* <img onClick={item?.others?.invoice} src={print_dark_icon} alt="" className="cursor-pointer" /> */}
            {!item?.IsDispatched &&
              <img onClick={item?.others?.addmoveManifestEdit} src={table_plus_icon} alt="" className="cursor-pointer" />
            }
          </div>

          // {!item?.IsDispatched &&
          //     <button className="text-[#ffff] bg-[#66BB6A] p-2 text-sm font-extralight rounded-lg" onClick={item?.others?.registerDispatch}>
          //       Register Dispatch
          //     </button>
          //   }
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const movementManifestDetails_TableHeader = [
  { key: 'manifest', title: 'MANIFEST' },
  { key: 'destination', title: 'DESTINATION' },
];
export const movementManifestDetails_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        manifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.groupWaybill}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal text-col_219653 capitalize py-[2px] rounded-[2rem] `}
            >
              {formatMoney(item.destination)}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const expectedMovementManifest_TableHeader = [
  { key: 'movementManifest', value: 'movement Manifest' },
  { key: 'dateCreated', value: 'date Created' },
  { key: 'status', value: 'status' },
  { key: 'movementTo', value: 'movement To' },
  { key: 'destinationCode', value: 'destination Code' },
  { key: 'others', value: '' },
];

export const expectedMovementManifest_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        movementManifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.movementManifest}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
        status: (
          <div className={`flex items-center `}>
            <span
              className={`text-col_fff text-txt_087_normal capitalize ${item.status === 'in progress'
                ? 'bg-col_f2c94c'
                : 'bg-col_219653'
                } px-2 py-1 rounded-[0.5rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        from: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.from}
            </span>
          </div>
        ),
        driverCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.driverCode}
            </span>
          </div>
        ),
        destinationCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destinationCode}
            </span>
          </div>
        ),
        destinationStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destinationStation}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img src={search_plus} alt="" className="cursor-pointer" />
            <img src={print_dark_icon} alt="" className="cursor-pointer" />
            <img src={table_plus_icon} alt="" className="cursor-pointer" />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const deliveryManifest_TableHeader = [
  { key: 'manifest', title: 'manifest' },
  { key: 'manifestType', title: 'manifest Type' },
  { key: 'dateMapped', title: 'date Mapped' },
  { key: 'dispatcher', title: 'dispatcher' },
  { key: 'isReceived', title: 'is Received' },
  { key: 'receiver', title: 'receiver' },
];

export const deliveryManifest_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        manifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.manifest}
            </span>
          </div>
        ),
        manifestType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.manifestType}
            </span>
          </div>
        ),
        dateMapped: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateMapped}
            </span>
          </div>
        ),
        dispatcher: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dispatcher}
            </span>
          </div>
        ),
        isReceived: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isReceived}
            </span>
          </div>
        ),
        receiver: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.receiver}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const unsignedDeliveryManifest_TableHeader = [
  { key: 'manifest', title: 'manifest' },
  { key: 'manifestType', title: 'manifest Type' },
  { key: 'dateMapped', title: 'date Mapped' },
  { key: 'dispatched', title: 'dispatched' },
  { key: 'dispatcher', title: 'dispatcher' },
  { key: 'received', title: 'received' },
  { key: 'dispatchRider', title: 'dispatch Rider' },
];

export const unsignedDeliveryManifest_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        manifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.manifest}
            </span>
          </div>
        ),
        manifestType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.manifestType}
            </span>
          </div>
        ),
        dateMapped: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateMapped}
            </span>
          </div>
        ),
        dispatched: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dispatched}
            </span>
          </div>
        ),
        dispatcher: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dispatcher}
            </span>
          </div>
        ),
        received: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.received}
            </span>
          </div>
        ),
        dispatchRider: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dispatchRider}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const superManifest_TableHeader = [
  { key: 'superManifest', title: 'super Manifest' },
  { key: 'manifestType', title: 'manifest Type' },
  { key: 'dateModified', title: 'date Modified' },
  { key: 'isDispatched', title: 'is Dispatched' },
  { key: 'dispatcher', title: 'dispatcher' },
  { key: 'isReceived', title: 'is Received' },
  { key: 'receiver', title: 'receiver' },
  { key: 'others', title: '' },
];

export const superManifest_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        superManifest: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.superManifest}
            </span>
          </div>
        ),
        manifestType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.manifestType}
            </span>
          </div>
        ),
        dateModified: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateModified}
            </span>
          </div>
        ),
        isDispatched: (
          <div className="flex items-center ">
            <span
              className={classNames(
                'text-txt_087_normal capitalize px-8 font-medium rounded-lg py-1',
                {
                  'bg-col_cdf4de text-col_219653': item.isDispatched === true,
                  'bg-col_fbdada text-col_e84e40': item.isDispatched === false,
                },
              )}
            >
              {item.isDispatched?.toString()}
            </span>
          </div>
        ),
        dispatcher: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dispatcher}
            </span>
          </div>
        ),
        isReceived: (
          <div className="flex items-center ">
            <span
              className={classNames(
                'text-txt_087_normal capitalize px-8 font-medium rounded-lg py-1',
                {
                  'bg-col_cdf4de text-col_219653': item.isReceived === true,
                  'bg-col_fbdada text-col_e84e40': item.isReceived === false,
                },
              )}
            >
              {item.isReceived?.toString()}
            </span>
          </div>
        ),
        receiver: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.receiver}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-4">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer min-w-[1.3rem]"
            />
            <img
              src={print_dark_icon}
              alt=""
              className="cursor-pointer min-w-[1.3rem]"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const expectedShipments_TableHeader = [
  { key: 'dateCreated', title: 'date Created' },
  { key: 'waybill', title: 'waybill' },
  { key: 'deliveryOption', title: 'delivery Option' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: 'others', title: '' },
];

export const expectedShipments_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        deliveryOption: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.deliveryOption}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCenter}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-4">
            <TableOthers viewRow={() => console.log('view row')} />
            <ColoredBox text="track" className="bg-col_e84e40" />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const shipmentCollection_TableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'arrivedDate', title: 'arrived Date' },
  { key: 'daySpent', title: 'day Spent' },
  { key: 'serviceCentre', title: 'Service Centre' },
  { key: 'actions', title: '' },
];

export const shipmentCollection_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        arrivedDate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.arrivedDate}
            </span>
          </div>
        ),
        daySpent: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal capitalize bg-col_fbdada text-col_e84e40 p-1 rounded-full`}
            >
              {item.daySpent}
            </span>
          </div>
        ),
        serviceCentre: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCentre}
            </span>
          </div>
        ),
        actions: (
          <div className="flex items-center justify-end gap-4 pr-4">
            <HiMagnifyingGlassPlus className="w-5 h-5 text-col_807e7c" />
            <button className="bg-col_e84e40 rounded-lg py-1 px-2 text-white text-sm font-medium capitalize">
              release
            </button>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const collectedShipment_TableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'collectedDate', title: 'collected Date' },
  { key: 'daySpent', title: 'day Spent' },
];

export const collectedShipment_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        collectedDate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.collectedDate}
            </span>
          </div>
        ),
        daySpent: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.daySpent}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const incidenceReport_TableHeader = [
  { key: 'serial', title: 'S/N' },
  { key: 'waybill', title: 'waybill' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: 'createdBy', title: 'created By' },
  { key: 'reason', title: 'reason' },
  { key: 'amount', title: 'amount' },
  { key: 'resolvedBy', title: 'resolved By' },
  { key: 'status', title: 'status' },
];

export const incidenceReport_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCenter}
            </span>
          </div>
        ),
        createdBy: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.createdBy}
            </span>
          </div>
        ),
        reason: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.reason}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.amount}
            </span>
          </div>
        ),
        resolvedBy: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.resolvedBy}
            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.status}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const manifestVisitMonitoring_tableHeader = [
  { key: 'serial', title: 'S/N' },
  { key: 'date', title: 'date' },
  { key: 'waybill', title: 'waybill' },
  { key: 'receiversName', title: 'receivers number' },
  { key: 'receiversNumber', title: 'receivers number' },
  { key: 'address', title: 'address' },
  { key: 'status', title: 'status' },
  { key: 'signature', title: 'signature' },
  { key: 'user', title: 'user' },
];

export const manifestVisitMonitoring_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const smsDeliveryLog_tableHeader = [
  { key: 'phoneNumber', title: 'phone Number' },
  { key: 'sms', title: 'SMS' },
  { key: 'status', title: 'status' },
  { key: 'sentTime', title: 'sent Time' },
  { key: 'receivedTime', title: 'received Time' },
];
export const smsDeliveryLog_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        sms: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.sms}
            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.status}
            </span>
          </div>
        ),
        sentTime: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.sentTime}
            </span>
          </div>
        ),
        receivedTime: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.receivedTime}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const inventiryTransaction_tableHeader = [
  { key: 'package', title: 'package' },
  { key: 'quantity', title: 'quantity' },
  { key: 'user', title: 'user' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: 'waybill', title: 'waybill' },
  { key: 'transactionType', title: 'transaction Type' },
  { key: 'date', title: 'date' },
];
export const inventiryTransaction_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        package: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.package}
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
        quantity: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.quantity}
            </span>
          </div>
        ),
        user: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.user}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCenter}
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        transactionType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.transactionType}
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const serviceCenterPackages_tableHeader = [
  { key: 'package', title: 'package' },
  { key: 'balance', title: 'balance' },
  { key: 'minimumRequired', title: 'minimum Required' },
  { key: 'dateCreated', title: 'date created' },
];
export const serviceCenterPackages_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        package: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.package}
            </span>
          </div>
        ),
        balance: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.balance}
            </span>
          </div>
        ),
        minimumRequired: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.minimumRequired}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const routeOptimisation_tableHeader = [
  { key: 'vehicleNumber', title: 'vehicle Number' },
  { key: 'eta', title: 'ETA' },
  { key: 'expectedDate', title: 'expected Date' },
  { key: 'dispatchedBy', title: 'dispatched By' },
  { key: 'dateDispatched', title: 'date Dispatched' },
  { key: 'movementManifestCode', title: 'movement Manifest Code' },
  { key: 'departureStation', title: 'departure Station' },
];

export const routeOptimisation_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        vehicleNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.vehicleNumber}
            </span>
          </div>
        ),
        eta: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.eta}
            </span>
          </div>
        ),
        expectedDate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.expectedDate}
            </span>
          </div>
        ),
        dispatchedBy: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dispatchedBy}
            </span>
          </div>
        ),
        dateDispatched: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateDispatched}
            </span>
          </div>
        ),
        movementManifestCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.movementManifestCode}
            </span>
          </div>
        ),
        departureStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departureStation}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const accountCustomer_tableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'merchantName', title: 'MERCHANT NAME' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'email', title: 'EMAIL' },
  { key: 'type', title: 'TYPE' },
  { key: 'status', title: 'STATUS' },
  { key: 'discount', title: 'DISCOUNT' },
  { key: 'settlementPeriod', title: 'SETTLEMENT PERIOD' },
  { key: 'balance', title: 'BALANCE' },
  { key: 'address', title: 'ADDRESS' },
  { key: 'city', title: 'CITY' },
  { key: 'state', title: 'STATE' },
  { key: 'returnOption', title: 'RETURN OPTION' },
  { key: 'returnServiceCenter', title: 'RETURN SERVICE CENTER' },
  { key: 'returnAddress', title: 'RETURN ADDRESS' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'date', title: 'DATE' },
  { key: 'rcod', title: 'RCOD' },
  { key: 'nubanAccount', title: 'NUBAN ACCT' },
  { key: 'nubanBank ', title: 'NUBAN BANK' },
  { key: 'others' },
];

export const accountCustomer_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        merchantName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.merchantName}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.status}
            </span>
          </div>
        ),
        discount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.discount}
            </span>
          </div>
        ),
        settlementPeriod: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.settlementPeriod}
            </span>
          </div>
        ),
        balance: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.balance}
            </span>
          </div>
        ),
        address: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.address}
            </span>
          </div>
        ),
        city: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.city}
            </span>
          </div>
        ),
        state: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.state}
            </span>
          </div>
        ),
        returnOption: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.returnOption}
            </span>
          </div>
        ),
        returnServiceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.returnServiceCenter}
            </span>
          </div>
        ),
        returnAddress: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.returnAddress}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
        rcod: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.rcod}
            </span>
          </div>
        ),
        nubanAccount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.nubanAccount}
            </span>
          </div>
        ),
        nubanBank: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.nubanBank}
            </span>
          </div>
        ),

        others: (
          <div className="flex items-center justify-end gap-2">
            <img
              onClick={item.others.view}
              src={search_plus}
              alt=""
              className="cursor-pointer"
            />
            <img
              onClick={item.others.edit}
              src={edit_icon}
              alt=""
              className="cursor-pointer"
            />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const shipmentReport_tableHeader = [
  { key: 'expenseType', title: 'expense Type' },
  { key: 'amount', title: 'amount' },
  { key: 'serviceCenter', title: 'service Center' },
  { key: 'description', title: 'description' },
  { key: 'createdBy', title: 'created By' },
  { key: 'date', title: 'date' },
];

export const shipmentReport_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        expenseType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.expenseType}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.amount}
            </span>
          </div>
        ),
        serviceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCenter}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        createdBy: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.createdBy}
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const pendingEcommerceRequest_tableHeader = [
  { key: 'businessEmail', title: 'BUSINESS EMAIL' },
  { key: 'businessOwnerName', title: 'BUSINESS OWNER NAME' },
  { key: 'officeAddress', title: 'OFFICE ADDRESS' },
  { key: 'state', title: 'STATE' },
  { key: 'contactName', title: 'CONTACT NAME' },
  { key: 'contactEmail', title: 'CONTACT EMAIL' },
  { key: 'contactPhone', title: 'CONTACT PHONE' },
  { key: 'dateCreated', title: 'DATE CREATED' },
  { key: 'shipmentStatus', title: 'SHIPMENT STATUS' },
  { key: 'releasedDate', title: 'RELEASED DATE' },
];

export const pendingEcommerceRequest_CreateTableData = (dataToMap: any) => {
  // const dataToMap = data
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        businessEmail: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.businessEmail}
            </span>
          </div>
        ),
        businessOwnerName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.businessOwnerName}
            </span>
          </div>
        ),
        officeAddress: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.officeAddress}
            </span>
          </div>
        ),
        state: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.state}
            </span>
          </div>
        ),
        contactName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.contactName}
            </span>
          </div>
        ),
        contactEmail: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.contactEmail}
            </span>
          </div>
        ),
        contactPhone: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.contactPhone}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
        shipmentStatus: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.shipmentStatus}
            </span>
          </div>
        ),
        releasedDate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.releasedDate}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const codShipments_tableHeader = [
  { key: 'waybill', title: 'WAYBILL' },
  { key: 'name', title: 'NAME' },
  { key: 'department', title: 'DEPARTMENT' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'isCod', title: 'IS COD' },
  { key: 'codAmount', title: 'COD AMOUNT' },
  { key: 'codstatus', title: 'COD STATUS' },
  { key: 'shipmentStatus', title: 'SHIPMENT STATUS' },
  { key: 'releasedDate', title: 'RELEASED DATE' },
];

export const codShipments_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        department: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.department}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destination}
            </span>
          </div>
        ),
        isCod: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isCod}
            </span>
          </div>
        ),
        codAmount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.codAmount}
            </span>
          </div>
        ),
        codstatus: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.codstatus}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const assignedCustomers_tableHeader = [
  { key: 'customerCode', title: 'CUSTOMER CODE' },
  { key: 'name', title: 'NAME' },
  { key: 'email', title: 'EMAIL' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'rank', title: 'RANK' },
];

export const assignedCustomers_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        customerCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.customerCode}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        rank: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.rank}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const ecommerceDelayedDeliveryReport_tableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'customerCode', title: 'customer Code' },
  { key: 'customerCompanyName', title: 'customer Company Name' },
  { key: 'dateCreated', title: 'date Created' },
  { key: 'shipmentAge', title: 'shipment Age' },
  { key: 'codAmount', title: 'COD amount' },
];

export const ecommerceDelayedDeliveryReport_CreateTableData = (
  dataToMap: any,
) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        customerCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.customerCode}
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        customerCompanyName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.customerCompanyName}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
        shipmentAge: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.shipmentAge}
            </span>
          </div>
        ),
        codAmount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.codAmount}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const codShipmentStatus_tableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'senderName', title: 'sender Name' },
  { key: 'receiverName', title: 'receiver Name' },
  { key: 'codAmount', title: 'COD Amount' },
  { key: 'status', title: 'status' },
  { key: 'date', title: 'date' },
  { key: 'receiver', title: 'receiver' },
];

export const codShipmentStatus_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        senderName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.senderName}
            </span>
          </div>
        ),
        receiverName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.receiverName}
            </span>
          </div>
        ),
        codAmount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.codAmount}
            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.status}
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
        receiver: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.receiver}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const cellulantSettlement_tableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'codAmount', title: 'COD Amount' },
  { key: 'codDescription', title: 'COD Description' },
  { key: 'reference', title: 'reference' },
  { key: 'departure', title: 'departure' },
  { key: 'destination', title: 'destination' },
  { key: 'date', title: 'date' },
];

export const cellulantSettlement_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

const hubManifestViewColumnHelper =
  createColumnHelper<HubMnanifestTableProps>();

export const HubManifestViewTableHeader = [
  hubManifestViewColumnHelper.accessor('index', {
    header: 'S/N',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  hubManifestViewColumnHelper.accessor('manifest', {
    header: 'Manifest',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  hubManifestViewColumnHelper.accessor('departure', {
    header: 'Departure Hub',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  hubManifestViewColumnHelper.accessor('destination', {
    header: 'Destination Hub',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  hubManifestViewColumnHelper.accessor('manifestType', {
    header: 'Manifest type',
    cell: (props) => (
      <span className="text-txt_087_normal text-white bg-[#8F8F8F] capitalize h-[1.625rem] px-[0.625rem] py-[0.25rem] rounded-lg">
        {props.getValue()}
      </span>
    ),
  }),
  hubManifestViewColumnHelper.accessor('dateMapped', {
    header: 'Date mapped',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  hubManifestViewColumnHelper.accessor('isDispatched', {
    header: 'Is Dispatched',
    cell: (props) => {
      const isDispatched = props.getValue();
      return (
        <span
          className={classNames(
            'text-txt_087_normal capitalize h-[1.625rem] px-[0.625rem] py-[0.25rem] rounded-lg',
            {
              'text-col_219653 bg-[#CDF4DE] ': isDispatched,
              'text-red-600 bg-[#FBDADA]': !isDispatched,
            },
          )}
        >
          {props.getValue() ? 'true' : 'false'}
        </span>
      );
    },
  }),
  hubManifestViewColumnHelper.accessor('dispatcher', {
    header: 'Dispatcher',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
];

const shipmentDeliveryReportHubColumnHelper =
  createColumnHelper<ShipmentDeliveryReportHubTableProps>();

export const shipmentDeliveryReportHubTableHeader = [
  shipmentDeliveryReportHubColumnHelper.accessor('index', {
    header: 'S/N',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  shipmentDeliveryReportHubColumnHelper.accessor('waybill', {
    header: 'Waybill',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  shipmentDeliveryReportHubColumnHelper.accessor('status', {
    header: 'Status',
    cell: (props) => (
      <span className="text-txt_087_normal capitalize text-[#FFB703] bg-[#FFF7E2] h-[1.625rem] px-[0.625rem] py-[0.25rem] rounded-lg">
        {props.getValue()}
      </span>
    ),
  }),
  shipmentDeliveryReportHubColumnHelper.accessor('grandTotal', {
    header: 'Grand Total',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {formatMoney(props.getValue())}
      </span>
    ),
  }),
  shipmentDeliveryReportHubColumnHelper.accessor('dateCreated', {
    header: 'Date Created',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
];

const HubManifestAssignementColumnHelper =
  createColumnHelper<HubManifestAssignementTableProps>();

export const HubManifestAssignementTableHeader = [
  HubManifestAssignementColumnHelper.accessor('index', {
    header: 'S/N',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  HubManifestAssignementColumnHelper.accessor('waybill', {
    header: 'Waybill',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
  HubManifestAssignementColumnHelper.accessor('dateArrived', {
    header: 'Date Arrived',
    cell: (props) => (
      <span className="text-txt_087_normal text-col_525252 capitalize">
        {props.getValue()}
      </span>
    ),
  }),
];

export const internationaShipment_tableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'outboundWaybill', title: 'outbound Waybill' },
  { key: 'trackingNumber', title: 'tracking Number' },
  { key: 'status', title: 'status' },
  { key: 'channel', title: 'channel' },
  { key: 'source', title: 'source' },
  { key: 'dateCreated', title: 'date Created' },
  { key: 'dateModified', title: 'date Modified' },
];

export const internationaShipment_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};



export const inboundShipmentTracker_tableHeader = [
  { key: 'waybill', title: 'waybill Number' },
  { key: 'departureServiceCenter', title: 'ORGIN ' },
  { key: 'estimatedDate', title: 'Estimated Arrival Date' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const inboundShipmentTracker_CreateTableData = (dataToMap: any) => {

  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Waybill}
            </span>
          </div>
        ),
        departureServiceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DepartureServiceCentre}
            </span>
          </div>
        ),
        estimatedDate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {/* {item.ExpectedArrival} */}
              {item.ExpectedArrival ? format(item.ExpectedArrival, "dd MMMM yyyy hh:mm a") : "--"}

            </span>
          </div>
        ),
        status: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {SHIPMENT_SCAN_STATUS[item.Status]}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer opacity-70 hover:opacity-100"
              onClick={item?.others?.openmodal}
            />

            <span className={`text-[#ffff] ${item?.IsReceived ? `bg-[#219653]` : `bg-[#EE786D]`}  font-extralight
              p-2 cursor-pointer rounded-lg`}
              onClick={item?.others?.status}>
              {item?.IsReceived ? "Release Shipment" : "Mark As Arrived"}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};


export const inboundShipment_tableHeader = [
  { key: 'waybill', title: 'waybill' },
  { key: 'departureServiceCenter', title: 'departure Service Center' },
  { key: 'destinationServiceCenter', title: 'destination Service Center' },
  { key: 'receiversName', title: 'receivers Name' },
  { key: 'receiversPhoneNumber', title: 'receivers Phone Number' },
  { key: 'shipmentStatus', title: 'shipment Status' },
  { key: 'paymentStatus', title: 'payment Status' },
  { key: 'shipmentDate', title: 'shipment Date' },
];

export const inboundShipment_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};
/////**** stopped here */

export const users_tableHeader = [
  { key: 'user', title: 'user' },
  { key: 'dateCreated', title: 'date Created' },
  { key: 'status', title: 'status' },
  { key: 'email', title: 'email' },
  { key: 'code', title: 'code' },
];

export const users_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const customerManagement_tableHeader = [
  { key: 'user', title: 'user' },
  { key: 'dateCreated', title: 'date Created' },
  { key: 'status', title: 'status' },
  { key: 'email', title: 'email' },
  { key: 'code', title: 'code' },
];

export const customerManagement_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const partnerManagement_tableHeader = [
  { key: 'user', title: 'user' },
  { key: 'dateCreated', title: 'date Created' },
  { key: 'status', title: 'status' },
  { key: 'email', title: 'email' },
  { key: 'type', title: 'type' },
  { key: 'code', title: 'code' },
];

export const partnerManagement_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const giglRoles_tableHeader = [
  { key: 'giglRole', title: 'GIGL role' },
  { key: 'dateCreated', title: 'date Created' },
];

export const giglRoles_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const systemRoles_tableHeader = [
  { key: 'systemRole', title: 'system role' },
];

export const systemRoles_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};


export const walletPaymentLogs_TableHeader = [
  { key: 'wallet', title: 'WALLET' },
  { key: 'name', title: 'NAME' },
  { key: 'amount', title: 'AMOUNT' },
  { key: 'reference', title: 'REFERENCE' },
  { key: 'type', title: 'TYPE' },
  { key: 'status', title: 'STATUS' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'date', title: 'DATE' },
  { key: 'transactions', title: '' },



];

export const walletPaymentLogs_CreateTableData = (data: any, serialno: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {serialno + i + 1}.
          </span>
        ),
        wallet: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.Wallet}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.Name ? item.Name : "--"}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Amount}
            </span>
          </div>
        ),
        reference: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.Reference}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {onlinePaymentType[item.OnlinePaymentType]}            </span>
          </div>
        ),
        status: (
          <div
            className={`${item?.status === 'Disabled' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
              } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item?.status}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item?.Description ? item?.Description : "--"}
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.DateCreated ? format(item.DateCreated, "dd MMMM yyyy") : ""} </span>
          </div>
        ),

        transactions: (
          <div className="flex items-center ">
            <button className="flex gap-1 items-center text-white bg-col_219653 px-3 py-1 rounded-lg text-sm font-medium">
              <MdAutorenew className="w-5 h-5" />  Revalidate Transaction
            </button>
          </div>
        ),


      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};



export const wallet_tableHeader = [
  { key: 'name', title: 'name' },
  { key: 'email', title: 'email' },
  { key: 'phoneNumber', title: 'phone Number' },
  { key: 'balance', title: 'balance' },
  { key: 'type', title: 'type' },
  { key: 'code', title: 'code' },
  { key: 'country', title: 'country' },
];

export const wallet_CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};



export const ecommerOverdueShipment_TableHeader = [
  { key: 'waybill', title: 'WAYBILL' },
  { key: 'departureCentre', title: 'DEPARTURE CENTRE' },
  { key: 'destinationCentre', title: 'DESTINATION CENTRE' },
  { key: 'dateCreated', title: 'DATE CREATED' },
  { key: 'actions', title: '' },
  { key: 'others', title: '' },
];

export const ecommerOverdueShipment_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        waybill: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.waybill}
            </span>
          </div>
        ),
        departureCentre: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.arrivedDate}
            </span>
          </div>
        ),
        destinationCentre: (
          <div className="flex items-center ">
            <span
              className={`text-txt_087_normal capitalize bg-col_fbdada text-col_e84e40 p-1 rounded-full`}
            >
              {item.daySpent}
            </span>
          </div>
        ),
        dateCreated: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dateCreated}
            </span>
          </div>
        ),
        actions: (
          <div className="flex items-center justify-end gap-4 pr-4 rounded-lg bg-col_219653 text-whit font-medium">
            <Button
              prefixIcon={white_check}
              text="Approve Shipment Return"
              onClick={item.actions.assign}
              className="py-1 px-2 text-white text-sm font-medium capitalize"
            />
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-2">
            <img
              onClick={item.others.view}
              src={search_plus}
              alt=""
              className="cursor-pointer"
            />
            <img
              onClick={item.others.print}
              src={print_dark_icon}
              alt=""
              className="cursor-pointer"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const _tableHeader = [
  { key: '', title: '' },
  { key: '', title: '' },
];

export const _CreateTableData = (dataToMap: any) => {
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <div className="flex item-start">
            <span className={`text-txt_087_normal text-col_525252`}>
              {i + 1}.
            </span>
          </div>
        ),
        column: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.column}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const ecommerceShipmentDetails_TableHeader = [
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'nature', title: 'NATURE' },
  { key: 'weight', title: 'WEIGHT/VOLUME(H*L*W)cm' },
];

export const ecommerceShipmentDetails_CreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        nature: (
          <div className="flex items-center">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.nature}
            </span>
          </div>
        ),
        weight: (
          <div className="flex items-center">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.weight}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};
