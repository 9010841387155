import { avatar, hamburger } from 'assets';
import { MenuDropdown } from 'components/menu/MenuDropdown';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useEffect, useRef, useState } from 'react';
// import { ImYoutube } from 'react-icons/im';   bell
// import { useNavigate } from 'react-router-dom';

type Props = {
	sidebar?: boolean;
	toggleSidebar?: any
	className?: string;
};
interface UserDetails {
	FirstName: string;
	LastName: string;
	Email: string;
	Department: string;
	Designation: string;
	PhoneNumber: string;
	PictureUrl: string | null;
	UserName: string;
	PrevilegeServiceCentre: number;
	ServiceCentre: string;
}

export const Navbar = ({ className }: Props) => {
	// const navigate = useNavigate()
	const dropdownRef = useRef(null);
	const [showMenu, setShowMenu] = useState(false);
	// const [showNotificationMenu, setShowNotificationMenu] = useState(false)
	const [userdetail, setUserDetails] = useState<UserDetails | null>(null)

	useOnClickOutside(dropdownRef, () => {
		setShowMenu(false);
	});

	useEffect(() => {
		const userDetailsStr = sessionStorage.getItem("agility_user");
		if (userDetailsStr) {
			try {
				const parsedDetails = JSON.parse(userDetailsStr) as UserDetails;
				setUserDetails(parsedDetails);
			} catch (error) {
				console.error("Error parsing user details:", error);
			}
		}
	}, []);

	return (
		<nav
			className={`${className} flex-grow pr-4 md:pr-[2rem] flex items-center top-0  right-0 box-border bg-col_141414 text-main-white`}
		>
			{/* <div className="logo-cont pl-6 md:pl-0 md:w-[13.5rem] flex items-center justify-center">
				<img
					src={logo}
					alt="logo"
					onClick={() => navigate('/')}
					className="cursor-pointer"
				/>
			</div> */}

			<div className="right ml-auto relative flex flex-col ">
				<div className="h-full w-full relative flex items-center gap-4">
					<span className="hidden md:block text-[0.875rem] font-normal text-col_fafafa">
						Good Morning {userdetail?.FirstName} {userdetail?.LastName}
					</span>
					<div className="flex items-center gap-3 md:gap-2 py-1 px-2 md:border md:border-main-white rounded-[8px]">
						{/* <div className='relative'>
							<img
								src={bell}
								alt=""
								className="hidden md:block cursor-pointer"
								onClick={() => setShowNotificationMenu(prevState => !prevState)}
							/>
							<div className="absolute top-[-0.25rem] right-0 w-[0.65rem] h-[0.65rem] rounded-full bg-col_e84e40" />

							{showNotificationMenu && (
								<div className="slide-down-from-up-content-reveal absolute w-full right-[-2.5rem] top-10 bg-white rounded-lg drop-shadow-lg border border-col_807e7c border-opacity-40 min-w-[29rem] max-h-[70vh] z-20 overflow-y-auto hide-scrollbar">
									<div className='sticky top-0 bg-white px-3 py-4 text-txt_087_600 text-col_807e7c'>
										Notifications
									</div>

									<div className="px-2">
										{[...Array(10)].map((_item, i) => (
											<div
												className="flex justify-between items-start border-b last:border-0 border-[#E7E7E7] pb-4 last:pb-0 hover:cursor-pointer hover:bg-col_ececec hover:bg-opacity-75 hover:rounded-lg p-2 w-full"
												key={i}
											>
												<div className="flex gap-4 items-start">
													<div className="flex gap-2 items-center">
														<div className="w-2 h-2 rounded-full bg-col_66bb6a" />
														<ImYoutube className='text-red-600 w-6 md:w-14 h-6 md:h-14' />
													</div>

													<div className='w-3/4 md:w-3/5 space-y-1'>
														<p className='text-sm text-col_525252'>
															Upcoming Zoom meeting: <span className='font-medium'>GIGL Zoom meeting | Friday 25th 2023 6:00pm. </span>
														</p>
														<p className='text-sm text-col_525252'>
															Click here to join
														</p>
														<p className='text-xs font-medium text-col_525252'>2 mins ago</p>
													</div>
												</div>

												<div className="flex flex-col md:flex-row items-end md:items-start gap-3">
													<img src='/images/gigl-brand.png' alt='' className='rounded-lg w-24 h-12' />
												</div>
											</div>
										))}
									</div>

									<div className='sticky bottom-0 w-full flex justify-between items-center text-[0.8125rem] leading-5 text-col_525252 px-3 py-4 bg-[#F0F0F0] rounded-b-lg'>
										<p onClick={() => navigate('/notifications')}>
											View all notifications
										</p>
										<p>Mark all as read</p>
									</div>
								</div>
							)}
						</div> */}
						<img
							src={avatar}
							alt=""
							className="block md:hidden cursor-pointer"
						/>
						<img
							src={hamburger}
							alt=""
							className="cursor-pointer"
							onClick={() => setShowMenu(!showMenu)}
						/>
					</div>
				</div>
				{showMenu && (
					<MenuDropdown
						ref={dropdownRef}
						className={`w-full absolute left-[-19%] top-[150%] z-50`}
						Email={userdetail?.Email}
					/>
				)}
			</div>
		</nav>
	);
};
