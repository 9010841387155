import { ReactElement, ReactNode } from "react"
import classNames from "classnames"

import { Button } from "components/button/Button"
import { Dropdown } from "components/dropdown/Dropdown"
import { TextInput } from "components/inputs/TextInput"
import { PhoneNumberInput } from "components/phoneNumberInput/PhoneNumberInput"
import { Radio } from "components/radio/Radio"
// import { Calendar } from 'components/calendar/Calendar';
import DateRangeCalendar from "components/calendar/DateRangeCalendar"
import { CiSearch } from "react-icons/ci"
interface CalendarProps {
  type: "calendar"
  startDate: Date | any
  setStartDate: React.Dispatch<React.SetStateAction<Date | any>>
  endDate: Date | any
  setEndDate: React.Dispatch<React.SetStateAction<Date | any>>
  className?: string
}

interface DropdownProps {
  type: "dropdown"
  placeholder?: string
  optionsArr?: { key: string; value: string }[]
  dropdownSelected?: string
  setDropdownSelected?: (e: any) => void
  isSearchable?: boolean
  className?: string
  flexWrapItems?: string
}

interface PhoneProps {
  type: "phone"
  phone: string
  setPhone?: (e: string) => void
  className?: string
  phoneInputClass?: string
}

interface SearchProps {
  type: "search"
  searchValue: string
  setSearchItem?: (e: string) => void
  placeholder: string
  className?: string
}

interface FileUploadProps {
  type: "file"
}

interface RadioProps {
  type: "radio"
  className?: string
  radioItems: string[]
  value: string
  setValue?: React.Dispatch<React.SetStateAction<any>>
}

interface RadioWithInputProps {
  type: "radioWithInput"
  radioItems: string[]
  value: string
  setValue?: React.Dispatch<React.SetStateAction<any>>
  inputPlaceholder?: string
  inputValue: string
  setInputValue?: React.Dispatch<React.SetStateAction<any>>
  inputClassName?: string
}

interface ButtonProps {
  type: "button"
  btnText: ReactNode | ReactElement
  btnAction?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  btnDisable?: boolean
}

interface AmountProps {
  type: "amount"
  title: string
  value: string | number
}

type ContentItem =
  | ({ type: "calendar" } & CalendarProps)
  | ({ type: "dropdown" } & DropdownProps)
  | ({ type: "phone" } & PhoneProps)
  | ({ type: "search" } & SearchProps)
  | ({ type: "radio" } & RadioProps)
  | ({ type: "button" } & ButtonProps)
  | ({ type: "amount" } & AmountProps)
  | ({ type: "file" } & FileUploadProps)
  | ({ type: "radioWithInput" } & RadioWithInputProps)

type Props = {
  contentArr: ContentItem[]
  className?: string
  flexWrapItems?: string
  contentChild?: ReactNode | ReactElement
  iconShow?: boolean
}

export const PageTop = ({
  className,
  contentArr,
  contentChild,
  flexWrapItems = "",
  iconShow,
  
}: Props) => {


  return (
    <div
      className={`head bg-col_f8f8f8 box-border ${className}`}
    >
      <div
        className={`w-full bg-col_ecebec flex gap-2 px-4 py-2  box-border ${flexWrapItems}`}
      >
        {contentArr?.map((item, i) => (
          <div key={i}>
            {item.type === "calendar" && (
              <div className="date-search w-full h-[2.5rem] flex-wrap tb:flex-nowrap flex items-center gap-3 tb:my-0 my-10">
                <div className="px-3 py-[0.5rem] rounded-[0.5rem] box-border w-full tb:w-auto flex items-center justify-between bg-col_f8f8f8 gap-x-4">
                  {/* <p className=" capitalize font-semibold text-[0.9rem] whitespace-nowrap text-col_525252">
                    Choose Date Range
                  </p> */}

                  <DateRangeCalendar
                    startDate={item.startDate}
                    endDate={item.endDate}
                    setStartDate={item.setStartDate}
                    setEndDate={item.setEndDate}
                  />

                  {/* <Calendar
                    calendarArrowIcon={true}
                    setDate={item.setStartDate}
                    className="p-0 z-9999 bg-col_fafafa"
                  /> */}
                </div>
                {/* <div className="px-3 py-[0.5rem] rounded-[0.5rem] box-border w-full tb:w-auto flex items-center justify-between bg-col_f8f8f8 gap-x-4">
                  <p className="capitalize font-semibold text-[0.9rem]  whitespace-nowrap text-col_525252">
                   End Date:
                  </p>
                  <Calendar calendarArrowIcon={true} setDate={item.setEndDate} className="p-0 z-9999 bg-col_fafafa" />
                </div> */}
              </div>
            )}
            {item.type === "dropdown" && (
              <Dropdown
                placeholder={item.placeholder}
                options={item.optionsArr}
                selected={item.dropdownSelected}
                setValue={item.setDropdownSelected}
                searchable={item.isSearchable}
                className={`w-full h-[2.5rem] bg-[#F8F8F8] rounded-md ${item.className}`}
                showFloatingLabel={true}
              />
            )}
            {item.type === "phone" && (
              <PhoneNumberInput
                value={item.phone}
                setValue={item.setPhone}
                className={item.className}
                phoneInputClass={item.phoneInputClass}
              />
            )}
            {item.type === "search" && (
              <TextInput
                value={item.searchValue}
                setValue={item.setSearchItem}
                placeholder={item.placeholder}
                className={`${item.className}`}
              />
            )}
            {item.type === "file" && <TextInput type="file" />}
            {item.type === "radio" && (
              <div className={`flex items-center gap-4 ${item.className} `}>
                {item.radioItems.map((ele, i) => (
                  <Radio
                    key={i}
                    label={ele}
                    name=""
                    defaultValue={item.radioItems[0]}
                    value={item.value}
                    onClick={() => item.setValue?.(ele)}
                    labelClass="capitalize text-[0.875rem]"
                    radioPointClass={
                      item.value === ele ? "bg-col_e84e40" : "bg-col_f8f8f8"
                    }
                  />
                ))}
              </div>
            )}

            {item.type === "radioWithInput" && (
              <div className="flex gap-4 md:gap-2 flex-col md:flex-row justify-between flex-grow">
                {item.radioItems?.map((inputRadio, i) => (
                  <div className="flex gap-2 items-center" key={i}>
                    <Radio
                      label={inputRadio}
                      defaultValue={item.radioItems[0]}
                      value={item.value}
                      onClick={() => item.setValue?.(inputRadio)}
                      name=""
                      radioPointClass={
                        item.value === inputRadio
                          ? "bg-col_e84e40"
                          : "bg-col_f8f8f8"
                      }
                    />

                    <TextInput
                      value={item.inputValue}
                      setValue={() => item.setInputValue?.(item.inputValue)}
                      placeholder={item.inputPlaceholder}
                      className={item.inputClassName}
                    />
                  </div>
                ))}
              </div>
            )}

            {item.type === "button" && (
              <Button
                text={item.btnText}
                PreIcon={!iconShow ? CiSearch : "" as any} 
                onClick={item.btnAction}
                // className={`rounded-[0.8rem] py-3 px-6 flex items-center justify-center text-txt_087_normal text-col_fff bg-col_e84e40 ${item.className}`}
                className={classNames(
                  `rounded-[0.5rem] py-3 px-4  flex items-center justify-center text-txt_087_normal text-col_fff ${item?.className? `${item?.className }`:`bg-col_e84e40` }`,
                  item.className,
                )}
                disabled={item.btnDisable}
              />
            )}
            {item.type === "amount" && (
              <div className="self-end flex items-center">
                <span className="text-col_525252 text-[1.62rem] font-fontInter mr-1">
                  {item.title}:dgfdgdf
                </span>
                <span className="text-red-500 text-[1.62rem] font-fontInter">
                  {item.value}
                </span>
              </div>
            )}
          </div>
        ))}

        <div className="flex md:ml-auto">{contentChild}</div>
      </div>
    </div>
  )
}
