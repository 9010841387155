import { useEffect, useState } from 'react'
import { Button } from 'components'
import { useNavigate } from 'react-router-dom'
import { TextInput } from 'components/inputs/TextInput'
import { useMutation } from '@tanstack/react-query'
import { loginApi } from 'apiRequest/auth/authenticationRequest'
import { btn_arrow_right_white } from 'assets'
import { toast } from 'react-toastify'
import { _isUserLoggedIn } from 'utils'


export const Login = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState<boolean>(false);


   useEffect(() => {
      if (!_isUserLoggedIn()) {
        navigate("/")
      }  else {
        navigate("/shipments/capture-shipments")
      }
    }, [])


  const { mutate, isPending } = useMutation({
    mutationFn: loginApi,
    onError: (error: Error) => {
      console.log("error=== ", error);
    },
    onSuccess: (res: any) => {
      if (res?.status == 200) {
        console.log("login res== ", res)
        sessionStorage.setItem('agility_token', res.data['access-token'])
        sessionStorage.setItem('agility_user', JSON.stringify({
          FirstName: res?.data?.FirstName,
          LastName: res?.data?.LastName,
          Email: res?.data?.Email,
          Department: res?.data?.Department,
          Designation: res?.data?.Designation,
          PhoneNumber: res?.data?.PhoneNumber,
          PictureUrl: res?.data?.PictureUrl,
          UserName: res?.data?.UserName,
          PrevilegeServiceCentre: res?.data?.PrevilegeServiceCentre,
          ServiceCentre: res?.data?.ServiceCentre
        }))
        toast("Login Successfully!", {
          type: 'success',
          position: 'top-right',
          theme: 'colored',
          autoClose: 6000,
        });

      }
      navigate("/shipments/capture-shipments")
    }
  })

  const loginUser = () => {
    // console.log("login fxn clicked")
    if (email && password) {
      mutate({
        email: email,
        password: password
      })
    }
    else {
      const auth = !email && !password
      toast(`${auth ? "email and password" : !email ? "email" : "password"} is required!`, {
        type: 'error',
        position: 'top-right',
        theme: 'colored',
        autoClose: 6000,
      });
    }
    console.log("Check env++++=== ", process.env.REACT_APP_AGILITY_DEV_BASE_URL)
  }

  const handlerember = () => {
    setRememberMe((prev) => !prev)
  }

  // console.log("login user-- ", loginUser)


  return (
    <div className="px-2 ">
      <p className='mb-6 text-[0.831rem] tb:text-[1rem] font-[500] text-col_525252'>
        Welcome back! Enter your email and password
        <br /> below to continue
      </p>
      <TextInput
        label='Email'
        placeholder='Enter your email'
        value={email}
        setValue={setEmail}
        className="w-full mb-6"
      />

      <TextInput
        label='Password'
        placeholder='Enter your password'
        value={password}
        setValue={setPassword}
        passwordInput={true}
        className="w-full mb-6"
        inputWrapperClass='py-8'
      />

      <div className='w-full flex items-center justify-between'>
        {/* <div className="flex gap-2">
          <div className='w-[1.5rem] h-[1.5rem] bg-col_f8f8f8 rounded-[3.4px] cursor-pointer'></div>
          <span className='text-[0.9rem] font-normal text-col_525252'>Remember Me</span>
        </div> */}

        <div className="flex gap-2">
          <div
            className="w-[1.5rem] h-[1.5rem] bg-gray-200 rounded-[3.4px] cursor-pointer"
            onClick={handlerember}
          >
            {rememberMe && <span>✔️</span>} {/* Display a checkmark if checked */}
          </div>
          <span className="text-[0.9rem] font-normal text-col_525252">
            Remember Me
          </span>
        </div>
        <span className='text-[0.9rem] font-normal text-col_e84e40 cursor-pointer hover:underline'
          onClick={() => navigate("/forgot-password")}
        >Forgot Password?</span>
      </div>

      <Button
        text="Login"
        loading={isPending}
        onClick={() => {
          loginUser()
        }}
        suffixIcon={btn_arrow_right_white}
        className='w-full mt-8 auth-btn-move text-col_ffffff px-6 py-4 flex items-center justify-center cursor-pointer text-[0.83rem] font-[600] rounded-[0.475rem] bg-col_e84e40'
      />
    </div>
  )
}

