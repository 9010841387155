// import { IsRestoringProvider } from '@tanstack/react-query';
import { createContext, ReactNode, useEffect, useState } from 'react';
import {
  ICaptureShipmentContextProps,
  ISpecificationProps,
} from 'utils/interfaces';

interface childProps {
  children: ReactNode;
}

export const CaptureShipmentContext = createContext<ICaptureShipmentContextProps | null>(null);

export const CaptureShipmentContextProvider = ({ children }: childProps) => {
  const [senderType, setSenderType] = useState<{
    title: string;
    id: number | null;
  }>({ title: 'individual', id: 1 });
  const [specificationState, setSpecificationState] = useState<
    ISpecificationProps[]
  >([]);

  const [multiShipment, setMultiShipment] = useState<any>([]);
  const [multiIDforDraft, setMultiIDforDraft] = useState<any>([]);
  const [vatAndInsuranceValue, setVatAndInsuranceValue] = useState("")
  const [shipmentValueForPrice, setShipmentValueForPrice] = useState("")


  const [senderData, setSenderData] = useState<any>({});
  const [receiverData, setReceiverData] = useState<any>({});
  const [shipmentData, setShipmentData] = useState<any>({});
  const [clearData, setClearData] = useState(false);
  const [clearItem, setClearItem] = useState(false);
  const [clearReceiver, setClearReceiver] = useState(false);
  const [shipmentStep, setShipmentStep] = useState<number>(1);
  const [shipmentSummary, setShipmentSummary] = useState<null | {
    Customer: { [key: string]: string | number };
    Shipments: { [key: string]: string | number };
  }>(null);

  const [draftShipment, setDraftShipments] = useState<any>([]);
  const [draftDelete, setDraftDelete] = useState<any>([]);
  const [waybillNumber, setWaybillNumber] = useState<string[]>([])
  const [paymentType, setPaymentType] = useState("")
  const [splitPayment, setSplitPayment] = useState<number | undefined>()
  const [splitPaymentType, setsplitPaymentType] = useState<string[]>([])
  const [shipMentsubCategory, setShipMentsubCategory] = useState<string>("individual")
  const [isMultiReciever, setIsMultiReciever] = useState<boolean>(false)
  const [companyCustomerName, setCompanyCustomerName] = useState<string>("")
  const [editShipmentRegister, setEditShipmentRegister] = useState<string>("")
  const [otpVerify, setOtpVerify] = useState<boolean>(false)
  const [coprateButtonDisableNext, setCoprateButtonDiableNext] = useState<boolean>(false)



  console.log(multiIDforDraft, "multiIDforDraft")
  const addSpecificationState = (e: { [key: string]: string }) => {
    // console.log("eeeess")
    // setSpecificationState((prev: any) => [...prev, e]);
    setSpecificationState((prev: any) => {
      if (Array.isArray(e)) {
        return [...e];
      } else {
        return [...prev, e];
      }
    });
  };


  const draftIDAdd = (idDraft: string) => {
    setMultiIDforDraft((prevID: string[]) =>
      prevID.includes(idDraft) ? prevID : [...prevID, idDraft]
    );
  };

  useEffect(() => {
    if (receiverData?.SpecialPackageId) {
      // const timer = setTimeout(() => {
      // setSpecificationState(receiverData?.SpecialPackageId);
      setReceiverData(receiverData);
      // }, 500); 

      // return () => clearTimeout(timer);
    }
  }, [receiverData?.SpecialPackageId || receiverData]);

  const filterSpecificationState = (id: string) => {
    setSpecificationState(
      specificationState?.filter((item: { id: string }) => item?.id !== id),
    );

    setReceiverData({
      ...receiverData,
      specificationState: receiverData?.specificationState?.filter((item: { id: string }) => item?.id !== id)
    });
  };



  const updateSpecificationContent = ({
    id,
    key,
    value,
  }: {
    id: string
    key: string
    value: string | number | boolean
  }) => {
    const index = specificationState?.findIndex((item: any) => item?.id === id)
    if (index !== -1) {
      console.log(value, "value")

      specificationState[index][key] = value
    };
    setReceiverData({ ...receiverData, specificationState })

  }


  const clearSpecificationState = () => {
    setSpecificationState([])
  }

  const getSpecification = (id: string) => {

    const getSpec = specificationState?.find((item: any) => item?.id === id);
    return getSpec;
  };

  const getTotalPrice = () => {
    // return specificationState?.reduce((total, item) => total + item.Price, 0);
    return specificationState?.reduce((total, item) => {
      const price = Number(item?.Price);
      const value = !isNaN(price) ? total + price : total;
      const roundedTotal = Math.round(value * 100) / 100;
      return roundedTotal


    }, 0);
  };
  const updateSenderData = ({ key, value, data }: any) => {
    //   if (data) setSenderData({ ...senderData, ...data });
    //   setSenderData({ ...senderData, [key]: value });
    // };
    if (key) {
      setSenderData((prevState: any) => ({
        ...prevState,
        [key]: value
      }));
    }
    if (data) {
      setSenderData((prevState: any) => ({
        ...prevState,
        ...data
      }));
    }
  };

  const addSenderObject = (e: { [key: string]: string | number | boolean }) => {
    // debugger;
    setSenderData(e);
  };

  const addReceiverObject = (e: {
    [key: string]: string | number | boolean;
  }) => {
    setReceiverData(e);
  };

  const updateReceiverData = ({ id, key, value, obj }: any) => {
    if (id && obj) {
      if (isMultiReciever === true) {
        setReceiverData((prev: any) => {
          const updatedState = { id: id, ...prev, ...obj };
          // updatedState[id] = obj;
          return updatedState;
        });
      }
      else {
        setReceiverData(() => {
          const updatedState = { id: id, ...obj };
          // updatedState[id] = obj;
          return updatedState;
        });
      }
    }
    else if (id) {
      setReceiverData({ id: id });
      // setReceiverData(
      //   receiverData.filter((item: { id: string | number }) => item.id !== id),
      // );
    } else if (key) {
      setReceiverData((prevState: any) => {
        const updatedState = { ...prevState };
        if (value === '') delete updatedState[key];
        else updatedState[key] = value;

        return updatedState;
      });
    } else {
      setReceiverData({});
    }
  };


  // console.log(receiverData,"receiverDatareceiverData")

  // const addToMultiShipment = (e: { [key: string]: string }) => {
  //      if (e !== null) {
  //     setMultiShipment((prev: any) => [...prev, e]);
  //   } else {
  //     setMultiShipment([]);
  //   }
  // };



  const addToMultiShipment = (e: { [key: string]: any }) => {

    // console.log(e,"eeeeeee")

    //   const newUpdateShipment = multiShipment?.some((shipment: any) => shipment?.receiver?.id === e?.receiver?.id)
    //   if(newUpdateShipment){
    //     setMultiShipment((prevShipments : any)  =>
    //       prevShipments.map((shipment : any) => 
    //         shipment.receiver?.id === receiverData?.id
    //           ? { ...shipment, receiver: receiverData } 
    //           : shipment
    //       )
    //     );
    //   }
    //   if (Array.isArray(e) && e.length === 0) {
    //   setMultiShipment([]);
    // } else if (e !== null && e !== undefined) {

    //   setMultiShipment((prev: any) => [...prev, e]);
    // } else {
    //        setMultiShipment([]);
    //   }   

    const existingShipmentIndex = multiShipment?.findIndex(
      (shipment: any) => shipment?.receiver?.id === receiverData?.id
    );


    // console.log(receiverData,"receiverData?.id")
    if (editShipmentRegister === "editShipment" && existingShipmentIndex !== -1) {
      setMultiShipment((prevShipments: any) =>
        prevShipments.map((shipment: any, index: number) =>
          index === existingShipmentIndex
            ? { ...shipment, receiver: receiverData, shipmentSpec: specificationState }
            : shipment
        )
      ); setEditShipmentRegister("")
    } else {
      if (e && Array.isArray(e) && e.length > 0) {
        setMultiShipment((prev: any) => [...prev, e]);
      } else if (e !== null && e !== undefined) {
        setMultiShipment((prev: any) => [...prev, e]);
      } else {
        setMultiShipment([]);
      }
    }
  };

  const clearMultiShipMentonCreateShipment = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Delay for 1 second
      setMultiShipment([]);
      console.log('Multi Shipment has been cleared.');
    } catch (error) {
      console.error('Error clearing multi shipment:', error);
    }
  };
  const filterMultiShipment = (id: string | number) => {
    // console.log(id,"ididididididid")
    setMultiShipment(
      multiShipment.filter((item: { id: string | number }) => item?.id !== id),
    );
  };




  const EditMultiShipment = (key: any) => {

    // console.log(key,"ddddsddssdsad")
    setReceiverData(key);
  }


  const addToshipmentData = (obj: any) => {
    setShipmentData(obj)
  }

  const updateShipmentData = ({ id, key, value, obj, arr }: any) => {
    // debugger;
    if (id) {
      setShipmentData({ id: id });
    } else if (key && value && !id) {
      setReceiverData({ ...receiverData, [key]: value });
      setShipmentData({ ...shipmentData, [key]: value });
    } else if (obj) {
      setShipmentData({ ...shipmentData, ...obj });
    } else if (key && arr) {
      setShipmentData({ ...shipmentData, [key]: arr });
    } else {
      setShipmentData({});
    }
  };



  const getSingleShipment = (id: string | number) => {
    const findShipment = multiShipment.find(
      (item: { id: string | number }) => item?.id === id,
    );
    setReceiverData(findShipment?.receiver);
    setShipmentData(findShipment?.shipment);
    setSpecificationState(findShipment?.shipmentSpec);
  };
  // const updateShipmentData = ({ id, key, value, obj, arr, receiver }: any) => {

  //     setShipmentData((prevData:any) => {
  //         if (id) {
  //             return { id };
  //         }
  //         else if (key && value) {
  //             return { ...prevData, [key]: value };
  //         }

  //         else if (obj) {
  //             return { ...prevData, ...obj };
  //         }
  //         else if (key && arr) {
  //             return { ...prevData, [key]: arr };
  //         }

  //         else if (receiver) {

  //             // Check if senderType allows multiple receivers
  //             if (senderType === "individual") {
  //                 // Single receiver case
  //                 return { ...prevData, receiver };
  //             } else  {
  //                 // Multiple receivers case
  //                 const updatedReceivers = prevData.receivers
  //                     ? [...prevData.receivers, receiver] // Add new receiver to existing array
  //                     : [receiver]; // Initialize array if none exists
  //                 return { ...prevData, receivers: updatedReceivers };
  //             }
  //         }

  //         return {};
  //     });
  // };
  const addDraftShipment = (draft: any) => {
    setDraftShipments(draft);
  };

  const deleteDraftShipment = (draft: any) => {
    setDraftDelete(draft);
  };

  const addToShipmentStep = (step: number) => {
    setShipmentStep(step);
  };


  // add waybill number from api
  const addWaybillNumber = (add: any) => {
    setWaybillNumber(add);
  };
  // add full payment type

  console.log(paymentType, "paymentType")
  const addPaymentType = (payment: any) => {
    setPaymentType(payment);
  };

  // add splitpayment
  const addSplitPayment = (ammount: number | undefined) => {
    setSplitPayment(ammount)
  }


  // for split payment type
  const addSplitPaymentType = (type: any) => {
    setsplitPaymentType(type)
  }



  const addshipMentsubCategory = (category: string) => {
    setShipMentsubCategory(category)
  }

  //  register shipment edit key
  const addEditShipmentRegister = (key: string) => {
    // editShipment
    setEditShipmentRegister(key)
  }




  return (
    <CaptureShipmentContext.Provider
      value={{
        specificationState,
        multiIDforDraft,
        senderType,
        multiShipment,
        senderData,
        shipmentData,
        receiverData,
        shipmentStep,
        clearData,
        shipmentSummary,
        draftShipment,
        addDraftShipment,
        clearItem,
        clearReceiver,
        waybillNumber,
        paymentType,
        splitPayment,
        splitPaymentType,
        shipMentsubCategory,
        isMultiReciever,
        companyCustomerName,
        editShipmentRegister,
        otpVerify,
        coprateButtonDisableNext,
        draftDelete,
        vatAndInsuranceValue,
        shipmentValueForPrice,
        setShipmentValueForPrice,
        setVatAndInsuranceValue,
        setCoprateButtonDiableNext,
        draftIDAdd,
        setOtpVerify,
        setMultiIDforDraft,
        setEditShipmentRegister,
        setCompanyCustomerName,
        setIsMultiReciever,
        setsplitPaymentType,
        setSplitPayment,
        setPaymentType,
        setWaybillNumber,
        setSenderType,
        addSpecificationState,
        filterSpecificationState,
        updateSpecificationContent,
        getSingleShipment,
        addToMultiShipment,
        filterMultiShipment,
        EditMultiShipment,
        updateSenderData,
        addSenderObject,
        addReceiverObject,
        updateShipmentData,
        setClearData,
        clearSpecificationState,
        updateReceiverData,
        setShipmentSummary,
        setClearItem,
        setClearReceiver,
        getSpecification,
        getTotalPrice,
        setShipmentStep,
        addToShipmentStep,
        addWaybillNumber,
        addPaymentType,
        addSplitPayment,
        addSplitPaymentType,
        setShipMentsubCategory,
        addshipMentsubCategory,
        clearMultiShipMentonCreateShipment,
        addToshipmentData,
        addEditShipmentRegister,
        setMultiShipment,
        setShipmentData,
        deleteDraftShipment,
        setReceiverData,

        // setSenderFirstName
      }}
    >
      {children}
    </CaptureShipmentContext.Provider>
  );
};