import { useEffect, useState } from "react"
import { processing_loader_gif } from "assets"
import { Header } from "components/header/Header"
import { PageTop } from "components/util-components/PageTop"
import { Modal } from "components/modal/Modal"
// import { useQuery } from "@tanstack/react-query"
import { MoveNewManifestModal } from "components/processing-packaging/MoveNewManifest.modal"
import {handleMovenewManifest} from "apiRequest/mani-fest/manifest"

export const MoveToNewManifest = () => {
  const [waybill, setWaybill] = useState("")
  const [modal, setModal] = useState(false)
  const [ManifestCode,setManifestCode]=useState<any>()

  const manifestdata=async()=>{
   try {
    const payload={
      ManifestCode:waybill     
    }
    const res= await handleMovenewManifest(payload)
    console.log(res);
    const manifestdata=res?.data?.ManifestCode
    if(manifestdata?.length>0){
      setModal(true)
      setManifestCode(manifestdata)
    }else{
      setModal(false)
    }
   } catch (error) {
    console.log(error);
    
   }
   
    
  }
 useEffect(()=>{
  // manifestdata()
 },[])

  return (
    <div className='w-full h-full'>
      <Header
        category="processing and packaging"
			  subCategory="move to new manifest"
			  mainRoute="/processing-and-packaging/move-to-new-manifest"
			  title="move to new manifest"
        className=""
      />
      <div className="content-header-margin">
        <PageTop
              contentArr={[
                  {
                    type: "search", 
                    placeholder: "Enter manifest number", 
                    searchValue: waybill, 
                    setSearchItem: setWaybill, 
                    className: "w-[20rem]"
                  },
                  {
                    type: "button", 
                    btnText: "create new manifest", 
                    btnAction: () => manifestdata()
                  },
              ]}
        />
        <main className="page-main-cont">
          <div className="w-full flex flex-col items-center justify-center mt-[2rem] ">
            <img src={processing_loader_gif} alt="" className="h-[10rem] " />
            <p className="text-center mt-4 text-col_525252 text-[1rem] ">
              Your manifest details would <br /> appear here
            </p>
          </div>
        </main>
      </div>

      <Modal
        modalOpen={modal}
        modalClose={()=> setModal(false)}
        closeIcon
        modalPatterBg
        modalChild={
          <MoveNewManifestModal ManifestCode={ManifestCode} />
        }
      />
    </div>
  )
}
