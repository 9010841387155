type PaymentType = {
    [key: number]: string;
};


export const paymentStatus: PaymentType = {
    0: 'Pending',
    1: 'Paid',
    2: 'Failed',
    3: 'Cancelled',
    4: 'Not Found',
    5: 'Processing',
    6: 'Partially Paid',
};

export const manifestTypeEnum: PaymentType = {
    0: "External",
    1: "Transit",
    2: "Delivery",
    3: "HUB",
    4: "Pickup",
    5: "PickupForDelivery",
};


export const depostiStatus: PaymentType =
{
    1: "Fidelity bank",
    2: "Zenith bank",
    3: "First bank",
}


export const statusColors: PaymentType = {
    0: 'bg-gray-500',  // Pending
    1: 'bg-green-500', // Paid
    2: 'bg-red-500',   // Failed
    3: 'bg-yellow-500',// Cancelled
    4: 'bg-gray-300',  // Not Found
    5: 'bg-blue-500',  // Processing
    6: 'bg-orange-500',// Partially Paid
};



export const MovementStatus: PaymentType = {
    0: "NoMovement",
    1: "InProgress",
    2: "EnRoute",
    3: "ProcessEnded"
};

export const PaymentServiceType: PaymentType = {
    0: 'Shipment',
    1: 'Cash on Delivery',
    2: 'Dispatch',
    3: 'Miscellaneous',
    4: 'Demurage',
};
export const paymentTypes: PaymentType = {
    0: 'Cash',
    1: 'Pos',
    2: 'Online',
    3: 'Wallet',
    4: 'Transfer',
    5: 'Partial',
    6: 'Waiver',
    7: 'USSD',
    8: 'Bank Transfer',
};

export const onlinePaymentType: PaymentType = {
    0: 'Paystack',
    1: 'TheTeller',
    2: 'Flutterwave',
    3: 'USSD',
    4: 'Cellulant',
    5: 'Sterling',
    6: 'Korapay',
};
export const creditDebitType: PaymentType = {
    0: "Credit",
    1: "Debit"
}
export const customerRankType: PaymentType = {
    0: "Downgrade",
    1: "Upgrade",
    2: "Cancel"
}
export const customerRank: PaymentType = {
    0: "Basic",
    1: "Class"
}
export const customerType: PaymentType = {
    0: "Company",
    1: "IndividualCustomer",
    2: "Partner"
}
export const companyStatus: PaymentType = {
    0: "Suspended",
    1: "Active",
    2: "Pending"
}
export const companyType: PaymentType = {
    0: "Corporate",
    1: "Ecommerce",
    2: "Client"
}
export const processingPartner: PaymentType = {
    0: "Cellulant",
    1: "Azapay",
    2: "Nomba",
    3: "Stellas",
    4: "MoniePoint"
}
export const regularEcommerceType: PaymentType = {
    0: "Regular",
    1: "Ecommerce",
    2: "ReturnForEcommerce",
    3: "International",
    4: "InternationalWeightLimit30",
    5: "InternationalWeightLimit70",
    6: "InternationalWeightLimit100",
    7: "InternationalDocument",
    8: "UPSDocument",
    9: "UPSNonDocument",
    10: "Corporate"
}
export const specialDomesticPackageType: PaymentType = {
    0: "Normal",
    1: "Special"
}
export const packageTransactionType: PaymentType = {
    0: "InflowToStore",
    1: "OutflowFromStore",
    2: "InflowToServiceCentre",
    3: "OutflowFromServiceCentre"
}
export const pickupOptions: PaymentType = {
    0: "HOMEDELIVERY",
    1: "SERVICECENTRE"
}
export const deliveryType: PaymentType = {
    0: "GOSTANDARDED",
    1: "GOFASTER"
}
export const shipmentType: PaymentType = {
    1: "Special",
    2: "Regular",
    3: "Ecommerce",
    4: "Store"
}

export const intShipmenttype: PaymentType = {
    0: "nonDocument",
    1: "document"
}
export const natureOfGoods: PaymentType = {
    0: "NORMAL",
    1: "DANGEROUS_GOODS",
    2: "FRAGILE",
    3: "KEEP_AT_ROOM_TEMPERATURE",
    4: "KEEP_UPRIGHT",
    5: "REFRIGERATED_ON_ARRIVAL",
    6: "SENSITIVE"
}
export const shipmentContactStatus: PaymentType = {
    0: "NotContacted",
    1: "Contacted"
}
export const auditPageName: PaymentType = {
    0: "login",
    1: "CaptureShipment",
    2: "CancelShipment",
    3: "ReleaseShipment",
    4: "ScanARF",
    5: "FundWallet",
    6: "DispatchVehicle",
    7: "MovementDispatchVehicle",
    8: "GenerateManifest"
}
export const auditAuctionname: PaymentType = {
    0: "Login",
    1: "Create",
    2: "Update",
    3: "Delete"
}
export const codStatusHistory: PaymentType = {
    1: "Created",
    2: "CollectedByDispatch",
    3: "RecievedAtServiceCenter",
    4: "Banked"
}
export const codMobileStatus: PaymentType = {
    0: "Initiated",
    1: "Collected",
    2: "Paid",
    3: "All"
}
export const depositStatus: PaymentType = {
    0: "Unprocessed",
    1: "Pending",
    2: "Deposited",
    3: "Verified"
}

export const bankName: PaymentType = {
    1: "Fidelity_bank",
    2: "Zenith_bank",
    3: "First_bank"
}
export const CODPaidOutStatus: PaymentType = {
    0: "PaidOut",
    1: "NotPaidOut"
}

export const LogisticCompany: PaymentType = {
    0: "GIG",
    1: "UPS",
    2: "DHL",
    3: "FEDEX"
}



export const DispatchCategory : PaymentType ={
     0 : "Regular",
     1 : "Transload"
  }


export const SHIPMENT_SCAN_STATUS: PaymentType = {
    0: "CRT", // 1ST SCAN FOR EVERY SHIPMENT
    1: "TRO", // Transfer shipment from where it is created to ops center within or to the hub
    2: "ARO", // Shipment arrived at the ops center or hub for further processing
    3: "DSC", // Shipment departs service center directly to destination
    4: "DTR", // Shipment departs service center to hub or another center that is not the final destination
    5: "AST", // Shipment arrives at the hub or another center that is not the final destination
    6: "DST", // Shipment in transit departs the hub or another center that is not the final destination
    7: "ARP", // Shipment arrived at the hub for further processing to another transit center or final destination
    8: "APT", // Shipment is already processed but in transit through the hub
    9: "DPC", // Shipment departs processing center
    10: "ARF", // Arrived at final destination
    11: "AD", // Daily scan until shipment is delivered
    12: "OKT", // Shipment delivered at the terminal to the receiver
    13: "GOP", // Scan at service center when shipment is transferred to ops for home delivery
    14: "WC", // Scan before shipment is taken out for delivery to receiver
    15: "OKC", // Scan to show shipment has been delivered
    16: "SSR", // Scan shipment for returns
    17: "SSC", // Scan shipment for canceled
    18: "SRR", // Scan shipment for reroute
    19: "SRC", // Scan for shipment received from courier
    20: "PRECRT", // 1ST scan for every preshipment created
    21: "PRESSC", // Scan preshipment for canceled
    22: "AHD", // Shipment arrived at final destination for home delivery
    23: "ATD", // When delivery attempt is made
    24: "ADF", // Shipment arrived at delivery facility to be processed out for delivery
    25: "MCRT", // Shipment created by customer
    26: "MAPT", // Shipment request accepted by dispatch rider
    27: "MSHC", // Shipment en route delivery
    28: "MSVC", // Shipment arrived at service center for onward processing
    29: "MAHD", // Shipment delivered
    30: "SMIM", // Shipment missed during arrival transit manifest
    31: "FMS", // Found missing shipment
    32: "MSCC", // Shipment canceled by customer
    33: "MSCP", // Shipment canceled by partner
    34: "DLD", // Delayed delivery
    35: "ACC", // Shipment arrives at collation center
    36: "DCC", // Shipment departs collation center
    37: "MNT", // Manifest not found in the supermanifest
    38: "DLP", // Delayed pickup
    39: "THIRDPARTY", // For 3rd party and gigm captain
    40: "PICKED", // Picked up
    41: "AISN", // Arrive international shipment to Nigeria
    42: "ISFUK", // Item shipped from UK
    43: "ODMV", // On the move
    44: "SRFS", // Shipment received from the store to GIG hub or center
    45: "IDH", // International shipment departs Houston in transit
    46: "IDK", // International shipment departs UK in transit
    47: "DUBC", // Delayed pickup by customer
    48: "ASCT", // Shipment arrives sorting center
    49: "RTNINIT", // Shipment return initiated
    50: "DASH", // Shipment scan damaged
    51: "ITTD", // Item delivery delay due to technical issues
    52: "ACCIT", // Shipment arrives collation center in transit
    53: "OVDSHP", // Overdue shipment
    54: "MRTD", // GIGGO shipment rerouted
    55: "MRTE", // GIGGO shipment returned
    56: "STKP", // Notification message on the app for demurrage
    57: "DBSSY", // Shipment departs for embassy
    58: "AIWH",
    59: "DIWN",
    60: "ARFH",
    61: "ARUS",
    62: "OFDU",
    63: "SHD",
    64: "ARFP",
    65: "ARFK",
    66: "IFA", // International shipment arrives Nigeria
    67: "UCC", // Under customs cleared
    68: "CLC", // Customs cleared
    69: "CHR", // Customs Hold - Reason
    70: "DFA" // Delivery unsuccessful
};
